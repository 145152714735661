import { useGLTF } from '@react-three/drei';
import React, { Suspense, useEffect, useMemo, useRef, useState } from 'react';
import { Canvas, ObjectMap, useFrame, useLoader, useThree } from 'react-three-fiber';
import * as THREE from 'three';
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader';
import { createAsset } from 'use-asset';
import { backgroundColor, RouteWrapper, usePageView, wrapAround } from './common';
import DefaultControls, { ControlsRef } from './DefaultControls';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { SSAARenderPass } from 'three/examples/jsm/postprocessing/SSAARenderPass.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js';
import ReactDOM from 'react-dom';
import { Card, CardContent, TextField, CardActions, Button, Typography, IconButton, useMediaQuery, CircularProgress } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import firebase from 'firebase/app';

import { GammaCorrectionShader } from 'three/examples/jsm/shaders/GammaCorrectionShader';
import { theme } from '.';
import { validate } from 'email-validator';
import { Overlay360Experience } from './Overlay360';
import { plantColors, plants, plantTitles } from './Plant';
import cherryAk360 from './media/Sativa_360.jpg';
import hellfire360 from './media/Hybrid_360.jpg';
import iceCreamCake360 from './media/Indica_360.jpg';
import * as H from 'history';

const experienceTitleImages: {[key: string]: {asset: string, hShift: string}} = {
  "cherry-ak": {
    asset: cherryAk360,
    hShift: "55%",
  },
  "hellfire": {
    asset: hellfire360,
    hShift: "53%",
  },
  "ice-cream-cake": {
    asset: iceCreamCake360,
    hShift: "43%",
  }
};

const experienceAsset = createAsset<GLTF & ObjectMap, [gltf: GLTF & ObjectMap]>(
  (gltf) => new Promise(resolve => {
    gltf.scene.traverse(o => {
      if(
        o.name === "ColliderIndica" ||
        o.name === "CollidersSativa" ||
        o.name === "ColliderHybrid002" ||
        o.name === "GeneralParticles001"
      ) o.visible = false;
    });
    resolve(gltf);
  })
);

type EmailFormProps = {
  onClose: () => void;
  plantType: string;
  history: H.History;
};

function EmailForm({onClose, plantType, history}: EmailFormProps) {
  const [email, setEmail] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");

  const largeScreen = useMediaQuery(theme.breakpoints.up('sm'));

  const [opacity, setOpacity] = useState(0);
  useEffect(() => {
    setOpacity(1);
  }, []);

  const validData = validate(email) && firstName.trim().length > 0  && lastName.trim().length > 0;

  const [submissionSuccess, setSubmissionSuccess] = useState<boolean | null | undefined>(undefined);

  useEffect(() => {
    firebase.analytics().logEvent("discount_form", {});
  }, []);

  const nextPlant = useMemo(() => plants[wrapAround(plants.findIndex(n => n === plantType) + 1, 3)], [plantType]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        pointerEvents: "all",
        backgroundColor: "transparent",
        backdropFilter: opacity === 0 ? "blur(0)" : "blur(8px)",
        transition: "backdropFilter 0.5s ease-in-out",
      }}
    >
      <Card
        style={{
          width: largeScreen ? "500px" : "90%",
          position: "relative",
          backgroundColor: "rgba(0, 0, 0, 0.75)",
          padding: "40px 0 20px 0",
          opacity,
          transition: "opacity 0.5s ease-in-out",
        }}
      >
        {
          submissionSuccess === undefined || submissionSuccess === null ? (
            <>
              <CardContent>
                <Typography
                  style={{
                    fontFamily: "NoirStd-Medium",
                    fontSize: "18px",
                    letterSpacing: "0.16em",
                    textAlign: "center",
                    color: "white",
                  }}
                  gutterBottom
                >
                  CONGRATULATIONS!
                </Typography>
                <Typography
                  style={{
                    fontFamily: "NoirStd-Regular",
                    fontSize: "12px",
                    textAlign: "center",
                    color: "white",
                    paddingBottom: "30px",
                  }}
                >
                  You’ve found a hidden discount code.
                </Typography>
                <Typography
                  style={{
                    fontFamily: "NoirStd-Medium",
                    fontSize: "12px",
                    letterSpacing: "0.16em",
                    textAlign: "center",
                    color: "white",
                  }}
                  gutterBottom
                >
                  ENTER YOUR EMAIL TO RECEIVE 20% OFF AT OUR GLASS HOUSE FARMS MERCH SHOP.
                </Typography>
                <form
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <TextField
                    label="E-Mail Address"
                    type="email"
                    name="email"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    InputLabelProps={{
                      style: {
                        color: "white",
                      },
                    }}
                    InputProps={{
                      style: {
                        color: "white",
                      }
                    }}
                    style={{
                      margin: "5px",
                    }}
                  />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      flexWrap: "wrap",
                    }}
                  >
                    <TextField
                      label="First Name"
                      type="firstname"
                      name="firstname"
                      value={firstName}
                      onChange={e => setFirstName(e.target.value)}
                      InputLabelProps={{
                        style: {
                          color: "white",
                          borderColor: "red",
                        },
                      }}
                      InputProps={{
                        style: {
                          color: "white",
                        }
                      }}
                      style={{
                        margin: "5px",
                      }}
                    />
                    <TextField
                      label="Last Name"
                      type="lastname"
                      name="lastname"
                      value={lastName}
                      onChange={e => setLastName(e.target.value)}
                      InputLabelProps={{
                        style: {
                          color: "white",
                        },
                      }}
                      InputProps={{
                        style: {
                          color: "white",
                        }
                      }}
                      style={{
                        margin: "5px",
                      }}
                    />
                  </div>
                </form>
              </CardContent>
              <CardActions
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  onClick={() => {
                    if(!process.env.REACT_APP_SUBMIT_MAIL) return;
                    setSubmissionSuccess(null);
                    fetch(process.env.REACT_APP_SUBMIT_MAIL, {
                      method: "POST",
                      body: JSON.stringify({
                        email,
                        firstName,
                        lastName,
                        plantType,
                      }),
                    }).then(() => {
                      setSubmissionSuccess(true);
                      firebase.analytics().logEvent("discount_submission", {
                        status: "success",
                      });
                    }).catch(() => {
                      setSubmissionSuccess(false);
                      firebase.analytics().logEvent("discount_submission", {
                        status: "fail",
                      });
                    });
                  }}
                  style={{
                    fontFamily: "NoirStd-Medium",
                    fontSize: "12px",
                    letterSpacing: "0.215em",
                    textAlign: "center",
                    backgroundColor: validData ? "#F6E92B" : "#f0e873",
                    color: validData ? "black" : "grey",
                    borderRadius: 0,
                    padding: "6px 25px",
                  }}
                  variant="contained"
                  color="primary"
                  disabled={submissionSuccess === null || !validData}
                  endIcon={submissionSuccess === null ? <div style={{transform: "scale(0.5)", margin: "-16px"}}><CircularProgress style={{color: "black"}} /></div> : undefined}
                >
                  CONTINUE
                </Button>
              </CardActions>
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                }}
              >
                <IconButton
                  onClick={onClose}
                  style={{
                    color: "white",
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </div>
            </>
          ) : (
            <>
              <CardContent>
                <Typography
                  style={{
                    fontFamily: "NoirStd-Medium",
                    fontSize: "18px",
                    letterSpacing: "0.16em",
                    textAlign: "center",
                    color: "white",
                  }}
                  gutterBottom
                >
                  {
                    submissionSuccess ? "Success!" : "Something went wrong..."
                  }
                </Typography>
                <Typography
                  component="div"
                  style={{
                    fontFamily: "NoirStd-Regular",
                    fontSize: "12px",
                    display: "flex",
                    justifyContent: "center",
                    color: "white",
                    paddingBottom: "30px",
                  }}
                >
                  {
                    submissionSuccess ? (
                      <div>
                        We will send you your discount code shortly. Please check your E-Mail inbox. Would you like to continue exploring or move on to the next experience? 
                      </div>
                    ) : (
                      <div>
                        We were unable to add your email to our discount code list. This could be due to several reasons:
                        <ul>
                          <li>
                            You have applied for a discount code with the same E-Mail address before
                          </li>
                          <li>
                            The E-Mail address you provided was incorrect
                          </li>
                          <li>
                            The first / last name you provided was incorrect
                          </li>
                        </ul>
                      </div>
                    )
                  }
                </Typography>
              </CardContent>
              <CardActions
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <Button
                  onClick={() => {
                    if(submissionSuccess) {
                      onClose();
                    } else {
                      setSubmissionSuccess(undefined);
                    }
                  }}
                  style={{
                    fontFamily: "NoirStd-Medium",
                    fontSize: "12px",
                    letterSpacing: "0.215em",
                    textAlign: "center",
                    backgroundColor: "#F6E92B",
                    color: "black",
                    borderRadius: 0,
                    padding: "6px 25px",
                  }}
                  variant="contained"
                  color="primary"
                >
                  {submissionSuccess ? "CONTINUE EXPLORING" : "TRY AGAIN"}
                </Button>
                {
                  submissionSuccess ? (
                    <Button
                      onClick={() => {
                        history.push(`/plant/${nextPlant}`);
                      }}
                      style={{
                        fontFamily: "NoirStd-Medium",
                        fontSize: "12px",
                        letterSpacing: "0.215em",
                        textAlign: "center",
                        backgroundColor: "#F6E92B",
                        color: "black",
                        borderRadius: 0,
                        padding: "6px 25px",
                        marginTop: "20px",
                      }}
                      variant="contained"
                      color="primary"
                    >
                      NEXT EXPERIENCE
                    </Button>
                  ) : null
                }
              </CardActions>
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                }}
              >
                <IconButton
                  onClick={onClose}
                  style={{
                    color: "white",
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </div>
            </>
          )
        }
      </Card>
    </div>
  );
}

type ExperienceSceneProps = {
  moveButtonContainerRef: React.Ref<HTMLDivElement>,
  active: boolean,
  controlsButtonContainerRef: React.RefObject<HTMLDivElement>,
  orbContainerRef: React.RefObject<HTMLDivElement>,
  onLoaded: () => void;
  controlsRef: React.RefObject<ControlsRef>;
  useDeviceControls: boolean;
  setUseDeviceControls: React.Dispatch<React.SetStateAction<boolean>>;
  history: H.History;
};

function ExperienceScene({gltf, moveButtonContainerRef, active, controlsButtonContainerRef, orbContainerRef, orbName, orbSignName, onLoaded, cameraName, initialOffset, controlsRef, useDeviceControls, setUseDeviceControls, plantType, history}: ExperienceSceneProps & { gltf: GLTF & ObjectMap, orbName: string, orbSignName: string, cameraName: string, initialOffset: THREE.Vector3, plantType: string }) {
  const sceneRef = useRef<THREE.Scene>();

  const { gl, camera, scene, size } = useThree();

  const myComposer = useMemo(() => {
    const composer = new EffectComposer(gl);
    composer.setPixelRatio(1);

    (camera as THREE.PerspectiveCamera).fov = 90;
    (camera as THREE.PerspectiveCamera).updateProjectionMatrix();
    const renderPass = new RenderPass( scene, camera );
    composer.addPass( renderPass );

    const ssaaRenderPassP = new SSAARenderPass(scene, camera, "white", 0);
    ssaaRenderPassP.sampleLevel = 2;
    composer.addPass(ssaaRenderPassP);

    const gammaCorrectionPass = new ShaderPass( GammaCorrectionShader );
    composer.addPass( gammaCorrectionPass );

    return composer;
  }, [gl, camera, scene]);

  useEffect(() => {
    myComposer.setSize(size.width, size.height);
  }, [size, myComposer]);

  useFrame((state, delta) => {
    if(!myComposer) return;
    myComposer.render(delta);
  }, 1);

  const orb = useMemo(() => gltf.scene.children.find(o => o.name === orbName) as THREE.Mesh, [gltf, orbName]);
  const [foundOrb, setFoundOrb] = useState(false);
  const [orbFormClosed, setOrbFormClosed] = useState(false);

  const [enableMove, setEnableMove] = useState(true);
  useEffect(() => {
    if(foundOrb && !orbFormClosed) {
      setEnableMove(false);
    } else {
      setEnableMove(true);
    }
  }, [foundOrb, orbFormClosed]);

  const mixer = useMemo(() => new THREE.AnimationMixer(scene), [scene]);

  useEffect(() => {
    for(const a of gltf.animations) {
      const action = mixer.clipAction(a);
      action.play();
    }
  }, [gltf, mixer]);

  const orbSign = useMemo(() => orb.children.find(o => o.name === orbSignName), [orb, orbSignName]) as THREE.Object3D;

  useFrame((state, delta) => {
    orbSign.lookAt(camera.position);
    orbSign.rotateX(Math.PI/2);
    mixer.update(delta);
  });

  useEffect(() => {
    if(!orbContainerRef.current) throw new Error("Button container must be specified!");

    ReactDOM.render(
      <>
        {
          foundOrb && !orbFormClosed ? (
            <EmailForm
              onClose={() => setOrbFormClosed(true)}
              plantType={plantType}
              history={history}
            />
          ) : null
        }
      </>,
      orbContainerRef.current
    );
  }, [orbContainerRef, foundOrb, orbFormClosed, plantType, history]);

  useFrame(() => {
    const orbDist = camera.position.distanceTo(orb.position);
    if(orbDist < 1) {
      setFoundOrb(true);
    }
  });

  const onLoadedRef = useRef(onLoaded);
  useEffect(() => {
    const cam = (gltf.scene.children.find(o => o.name === cameraName) as THREE.Object3D).children[0];
    if(!controlsRef.current?.setPlayerPosition || !controlsRef.current?.setCameraQuaternion) return;
    controlsRef.current.setPlayerPosition(cam.getWorldPosition(new THREE.Vector3()).add(initialOffset));
    controlsRef.current.setCameraQuaternion(cam.getWorldQuaternion(new THREE.Quaternion()));
    onLoadedRef.current();
  }, [gltf, cameraName, initialOffset, controlsRef]);

  return (
    <>
      <scene
        ref={sceneRef}
      >
        <primitive
          object={gltf.scene}
        />
        <mesh
          geometry={orb.geometry}
          position={orb.position}
          rotation={orb.rotation}
          scale={orb.scale}
          material={orb.material}
          onPointerDown={e => {
            e.stopPropagation();
            setOrbFormClosed(false);
          }}
        />
        {
          active ? (
            <DefaultControls
              buttonContainer={controlsButtonContainerRef}
              height={0.65}
              move
              pauseMove={!enableMove}
              obstacleGroup={gltf.scene.children.find(c => c.name === "ColliderIndica" || c.name === "CollidersSativa" || c.name === "ColliderHybrid002") as THREE.Object3D}
              ref={controlsRef}
              useDeviceControls={useDeviceControls}
              setUseDeviceControls={setUseDeviceControls}
            />
          ) : null
        }
      </scene>
    </>
  )
}

function ExperienceSceneCherryAk({moveButtonContainerRef, active, controlsButtonContainerRef, orbContainerRef, onLoaded, controlsRef, useDeviceControls, setUseDeviceControls, history}: ExperienceSceneProps) {
  const rawGltf = useGLTF("/4_18_2021_Sativa.gltf");
  const gltf = experienceAsset.read(rawGltf);

  return (
    <ExperienceScene
      gltf={gltf}
      moveButtonContainerRef={moveButtonContainerRef}
      active={active}
      controlsButtonContainerRef={controlsButtonContainerRef}
      orbContainerRef={orbContainerRef}
      orbName={"OrbSativa"}
      orbSignName={"OrbSign001"}
      onLoaded={onLoaded}
      cameraName={"CameraSativa"}
      initialOffset={new THREE.Vector3(0, 0, 1)}
      controlsRef={controlsRef}
      useDeviceControls={useDeviceControls}
      setUseDeviceControls={setUseDeviceControls}
      plantType={"cherry-ak"}
      history={history}
    />
  );
}

function ExperienceSceneIceCreamCake({moveButtonContainerRef, active, controlsButtonContainerRef, orbContainerRef, onLoaded, controlsRef, useDeviceControls, setUseDeviceControls, history}: ExperienceSceneProps) {
  const rawGltf = useGLTF("/4_10_2021_Indica.gltf");
  const gltf = experienceAsset.read(rawGltf);

  const [envMapTexture] = useLoader(THREE.TextureLoader, ["/textures/indica_envEdit.jpg"]);

  useEffect(() => {
    envMapTexture.mapping = THREE.EquirectangularReflectionMapping;

    const emissiveBox = gltf.scene.children.find(o => o.name === "EmissiveBOXEIndica") as THREE.Mesh;
    (emissiveBox.material as THREE.MeshStandardMaterial).emissive = new THREE.Color("#6b3aa8").convertSRGBToLinear();
    (emissiveBox.material as THREE.MeshStandardMaterial).envMap = envMapTexture;
    (emissiveBox.material as THREE.MeshStandardMaterial).envMapIntensity = 10;
    (emissiveBox.material as THREE.MeshStandardMaterial).opacity = 0.1;
    (emissiveBox.material as THREE.MeshStandardMaterial).roughness = 0.1;
    (emissiveBox.material as THREE.MeshStandardMaterial).metalness = 1;
    
    envMapTexture.needsUpdate = true;
    (emissiveBox.material as THREE.MeshStandardMaterial).needsUpdate = true;

    const emissiveBox1 = gltf.scene.children.find(o => o.name === "EmissiveBOXEIndica") as THREE.Mesh;
    (emissiveBox1.material as THREE.MeshStandardMaterial).emissive = new THREE.Color("#6b3aa8").convertSRGBToLinear();
    (emissiveBox1.material as THREE.MeshStandardMaterial).envMap = envMapTexture;
    (emissiveBox1.material as THREE.MeshStandardMaterial).envMapIntensity = 1;
    (emissiveBox1.material as THREE.MeshStandardMaterial).opacity = 0.9;
    (emissiveBox1.material as THREE.MeshStandardMaterial).roughness = 0.1;
    (emissiveBox1.material as THREE.MeshStandardMaterial).metalness = 1;
  }, [envMapTexture, gltf]);

  return (
    <ExperienceScene
      gltf={gltf}
      moveButtonContainerRef={moveButtonContainerRef}
      active={active}
      controlsButtonContainerRef={controlsButtonContainerRef}
      orbContainerRef={orbContainerRef}
      orbName={"OrbIndica"}
      orbSignName={"OrbSign002"}
      onLoaded={onLoaded}
      cameraName={"CameraIndica"}
      initialOffset={new THREE.Vector3(0, 0, 0)}
      controlsRef={controlsRef}
      useDeviceControls={useDeviceControls}
      setUseDeviceControls={setUseDeviceControls}
      plantType={"ice-cream-cake"}
      history={history}
    />
  );
}

function ExperienceSceneHellfire({moveButtonContainerRef, active, controlsButtonContainerRef, orbContainerRef, onLoaded, controlsRef, useDeviceControls, setUseDeviceControls, history}: ExperienceSceneProps) {
  const rawGltf = useGLTF("/4_18_2021_Hybrid.gltf");
  const gltf = experienceAsset.read(rawGltf);

  return (
    <ExperienceScene
      gltf={gltf}
      moveButtonContainerRef={moveButtonContainerRef}
      active={active}
      controlsButtonContainerRef={controlsButtonContainerRef}
      orbContainerRef={orbContainerRef}
      orbName={"OrbHybrid"}
      orbSignName={"OrbSign"}
      onLoaded={onLoaded}
      cameraName={"CameraHybrid"}
      initialOffset={new THREE.Vector3(0, 0, 0.3)}
      controlsRef={controlsRef}
      useDeviceControls={useDeviceControls}
      setUseDeviceControls={setUseDeviceControls}
      plantType={"hellfire"}
      history={history}
    />
  );
}

type ExperienceProps = {
  active: boolean;
  plantType: string;
  viewportOverlayRef: React.RefObject<HTMLDivElement>;
  history: H.History;
};

export default function Experience({active, plantType, viewportOverlayRef, history}: ExperienceProps) {
  const controlsButtonContainerRef = useRef<HTMLDivElement>(null);
  const moveButtonContainerRef = useRef<HTMLDivElement>(null);
  const orbContainerRef = useRef<HTMLDivElement>(null);

  const controlsRef = useRef<ControlsRef>(null);
  const [useDeviceControls, setUseDeviceControls] = useState(false);

  const [loaded, setLoaded] = useState(false);
  const loadedRef = useRef(false);

  const experienceScene = useMemo(() => {
    switch(plantType) {
      case "cherry-ak":
        return (
          <ExperienceSceneCherryAk
            moveButtonContainerRef={moveButtonContainerRef}
            active={active}
            controlsButtonContainerRef={controlsButtonContainerRef}
            orbContainerRef={orbContainerRef}
            onLoaded={() => {
              setLoaded(true);
              loadedRef.current = true;
            }}
            controlsRef={controlsRef}
            useDeviceControls={useDeviceControls}
            setUseDeviceControls={setUseDeviceControls}
            history={history}
          />
        );
      case "ice-cream-cake":
        return (
          <ExperienceSceneIceCreamCake
            moveButtonContainerRef={moveButtonContainerRef}
            active={active}
            controlsButtonContainerRef={controlsButtonContainerRef}
            orbContainerRef={orbContainerRef}
            onLoaded={() => {
              setLoaded(true);
              loadedRef.current = true;
            }}
            controlsRef={controlsRef}
            useDeviceControls={useDeviceControls}
            setUseDeviceControls={setUseDeviceControls}
            history={history}
          />
        );
      case "hellfire":
        return (
          <ExperienceSceneHellfire
            moveButtonContainerRef={moveButtonContainerRef}
            active={active}
            controlsButtonContainerRef={controlsButtonContainerRef}
            orbContainerRef={orbContainerRef}
            onLoaded={() => {
              setLoaded(true);
              loadedRef.current = true;
            }}
            controlsRef={controlsRef}
            useDeviceControls={useDeviceControls}
            setUseDeviceControls={setUseDeviceControls}
            history={history}
          />
        );
      default:
        return null;
    }
  }, [plantType, active, useDeviceControls, history]);

  usePageView(active ? true : false, `/experience/${plantType}`, `Experience ${plantTitles[plantType ?? ""]?.title}`);

  return (
    <RouteWrapper
      active={active}
      style={{
        backgroundColor,
      }}
    >
      <div
        style={{
          width: "100%",
          height: "100%",
        }}
      >
        <Canvas
          onCreated={() => {
            return new Promise<void>(res => {
              const i = setInterval(() => {
                if(loadedRef.current) {
                  res();
                  clearInterval(i);
                }
              }, 100);
            });
          }}
          colorManagement={true}
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          }}
        >
          <Suspense
            fallback={null}
          >
            {
              experienceScene
            }
          </Suspense>
        </Canvas>
        <div
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          }}
          ref={controlsButtonContainerRef}
        />
        <div
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            pointerEvents: "none",
          }}
          ref={orbContainerRef}
        />
      </div>
      <Overlay360Experience
        controlsRef={controlsRef}
        useDeviceControls={useDeviceControls}
        plantTitle={plantTitles[plantType].title}
        plantSubtitle={plantTitles[plantType].subtitle}
        loaded={loaded}
        plantColor={plantColors[plantType]}
        titleImage={experienceTitleImages[plantType].asset}
        titleImageHPosition={experienceTitleImages[plantType].hShift}
      />
    </RouteWrapper>
  );
}
