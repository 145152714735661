import { Icon } from "@material-ui/core";
import React from "react";
import logoCherryAk from './media/logoCherryAk.svg';
import logoIceCreamCake from './media/logoIceCreamCake.svg';
import logoHellfire from './media/logoHellfire.svg';
import logoExperience from './media/logoExperience.svg';
import logoDetails from './media/logoDetails.svg';
import logoInformation from './media/logoInformation.svg';
import logoGhf from './media/ghfLogo.svg';
import logoInstagram from './media/instagramLogo.svg';
import logo360 from './media/logo360.svg';
import logo360White from './media/logo360White.svg';
import LogoTouch from '@material-ui/icons/TouchAppOutlined';
import logoDiscount from './media/logoDiscount.svg';
import logoDiscountWhite from './media/logoDiscountWhite.svg';
import logoKeyboard from './media/keyboardInput.svg';

type LogoProps = {
  type: string;
};

export default function Logo({type}: LogoProps) {
  return (
    <Icon
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {(() => {
        switch(type) {
          case "logoCherryAk":
            return <img src={logoCherryAk} alt="Cherry AK Logo" />;
          case "logoIceCreamCake":
            return <img src={logoIceCreamCake} alt="Ice Cream Cake Logo" />;
          case "logoHellfire":
            return <img src={logoHellfire} alt="Hellfire Logo" />;
          case "experience":
            return <img src={logoExperience} alt="experience logo" />;
          case "details":
            return <img src={logoDetails} alt="details logo" />;
          case "ghf":
            return <img src={logoGhf} alt="GHF logo" width="48px" height="48px" />;
          case "instagram":
            return <img src={logoInstagram} alt="Instagram logo" width="48px" height="48px"/>;
          case "logo360":
            return <img src={logo360} alt="360 logo" width="48px" height="48px"/>;
          case "logo360White":
            return <img src={logo360White} alt="360 logo" width="48px" height="48px"/>;
          case "logoTouch":
            return <LogoTouch fontSize="large" style={{padding: "6.5px"}} />;
          case "logoDiscount":
            return <img src={logoDiscount} alt="discount logo" width="48px" height="48px"/>;
          case "logoDiscountWhite":
            return <img src={logoDiscountWhite} alt="discount logo" width="48px" height="48px" style={{backgroundColor: "black"}} />;
          case "logoKeyboard":
            return <img src={logoKeyboard} alt="discount logo" width="48px" height="48px" />;
          case "logoMouse":
            return <svg style={{transform: "scale(1.4)"}} className="MuiSvgIcon-root jss79" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M20 9c-.04-4.39-3.6-7.93-8-7.93S4.04 4.61 4 9v6c0 4.42 3.58 8 8 8s8-3.58 8-8V9zm-2 0h-5V3.16c2.81.47 4.96 2.9 5 5.84zm-7-5.84V9H6c.04-2.94 2.19-5.37 5-5.84zM18 15c0 3.31-2.69 6-6 6s-6-2.69-6-6v-4h12v4z"></path></svg>;
          case "information":
          default:
            return <img src={logoInformation} alt="information logo" />;
        }
      })()}
    </Icon>
  );
}