import React, { useCallback, useEffect, useRef } from 'react';
import {
  useLocation,
  matchPath,
  useHistory,
} from "react-router-dom";

import Greenhouse from './Greenhouse';
import PlantViewer, { allPlantTypes } from './Plant';
import Experience from './Experience';
import Home from './Home';
import { useTheme, IconButton, useMediaQuery, Button } from '@material-ui/core';
import VolumeOffIcon from '@material-ui/icons/VolumeOff';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import { useState } from 'react';
// @ts-ignore
import soundtrackCherryAk from './media/soundtrackCherryAk.wav';
// @ts-ignore
import soundtrackIceCreamCake from './media/soundtrackIceCreamCake.wav';
// @ts-ignore
import soundtrackHellfire from './media/soundtrackHellfire.wav';
import { useCookies } from 'react-cookie';
import ghfLogo from './media/ghfLogo.svg';
import { Menu as MenuIcon, Close as CloseIcon } from '@material-ui/icons';
import { theme } from '.';
import ghfGradient from './media/GHF_gradient-03 2.png';
import Logo from './Logo';
import firebase from 'firebase/app';

type AgeGate21Props = {
  ageConfirmed: boolean;
  setAgeConfirmed: React.Dispatch<React.SetStateAction<boolean>>;
};

function AgeGate21({setAgeConfirmed}: AgeGate21Props) {
  const [{age_gate}, setCookie] = useCookies(['age_gate']);

  const upMd = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <>
      {
        age_gate !== "21" ? (
          <div
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              backdropFilter: "blur(8px)",
              zIndex: 1,
              overflow: "auto",
            }}
          >
            <form
              style={{
                fontWeight: 500,
                color: "#1a1919",
                lineHeight: "1.9em",
                fontFamily: "'Source Serif Pro',Georgia,'Times New Roman',serif",
                textAlign: "center",
                border: 0,
                outline: 0,
                fontSize: "100%",
                verticalAlign: "baseline",
                margin: "0 auto",
                maxWidth: "500px",
                width: "100%",
                background: "#fff",
                position: "relative",
                boxSizing: "border-box",
                padding: "40px 0 10px",
              }}
            >
              <img
                style={{
                  fontFamily: "'Source Serif Pro',Georgia,'Times New Roman',serif",
                  textAlign: "center",
                  color: "#333",
                  lineHeight: "1em",
                  fontWeight: 500,
                  letterSpacing: "3px",
                  margin: 0,
                  padding: 0,
                  border: 0,
                  outline: 0,
                  fontSize: "100%",
                  verticalAlign: "baseline",
                  background: "transparent",
                  height: "auto",
                  boxSizing: "border-box",
                  maxWidth: upMd ? "50%" : "20%",
                }}
                alt="Glasshouse Farms Logo"
                src={ghfLogo}
              />
              <p
                style={{
                  fontWeight: 500,
                  fontFamily: "'Source Serif Pro',Georgia,'Times New Roman',serif",
                  textAlign: "center",
                  border: 0,
                  outline: 0,
                  verticalAlign: "baseline",
                  background: "transparent",
                  color: "#010101",
                  fontSize: "15px",
                  lineHeight: "24px",
                  letterSpacing: "0.54px",
                  padding: 0,
                  margin: "20px 0 15px",
                  boxSizing: "border-box",
                }}
              >
                This site contains cannabis
              </p>
              <p
                style={{
                  fontWeight: 500,
                  textAlign: "center",
                  padding: 0,
                  border: 0,
                  outline: 0,
                  verticalAlign: "baseline",
                  background: "transparent",
                  margin: "0 0 10px",
                  fontFamily: "'noir-pro-medium',Helvetica,Arial,Lucida,sans-serif",
                  textTransform: "uppercase",
                  fontSize: upMd ? "46px" : "25px",
                  color: "#010101",
                  letterSpacing: "6px",
                  lineHeight: "36px",
                  marginBottom: upMd ? "40px" : "10px",
                  boxSizing: "border-box",
                  paddingBottom: 0,
                }}
              >
                ARE YOU 21+?
              </p>
              <div
                style={{
                  display: "flex",
                  flexDirection: upMd ? "column" : "row",
                  margin: "0 50px",
                }}
              >
                <button
                  style={{
                    boxSizing: "border-box",
                    width: "280px",
                    border: "1px solid #010101",
                    padding: "18px",
                    fontSize: "15px",
                    lineHeight: "21px",
                    margin: "7px auto",
                    fontFamily: "'noir-pro-medium',Helvetica,Arial,Lucida,sans-serif",
                    textTransform: "uppercase",
                    letterSpacing: "2px",
                    cursor: "pointer",
                    background: "#29ABE2",
                    color: "#fff",
                    borderColor: "#29ABE2",
                  }}
                  onClick={e => {
                    e.preventDefault();

                    firebase.analytics().logEvent("age_gate_21", {
                      answer: "yes",
                    });

                    const numExpiryDays = 30;
                    setCookie("age_gate", 21, {
                      domain: ".glasshousefarms.org",
                      expires: new Date(Date.now() + 86400*1000*numExpiryDays),
                    });
                    setCookie("age_gate", 21, {
                      expires: new Date(Date.now() + 86400*1000*numExpiryDays),
                    });
                    setAgeConfirmed(true);
                  }}
                >
                  Yes
                </button>
                <button
                  style={{
                    boxSizing: "border-box",
                    width: "280px",
                    border: "1px solid #010101",
                    background: "inherit",
                    padding: "18px",
                    fontSize: "15px",
                    lineHeight: "21px",
                    margin: "7px auto",
                    fontFamily: "'noir-pro-medium',Helvetica,Arial,Lucida,sans-serif",
                    textTransform: "uppercase",
                    color: "#010101",
                    letterSpacing: "2px",
                    cursor: "pointer",
                  }}
                  onClick={e => {
                    e.preventDefault();

                    firebase.analytics().logEvent("age_gate_21", {
                      answer: "no",
                    });

                    window.location.href = "https://www.google.com";
                  }}
                >
                  No
                </button>
              </div>
              <p
                style={{
                  fontWeight: 500,
                  fontFamily: "'Source Serif Pro',Georgia,'Times New Roman',serif",
                  textAlign: "left",
                  border: 0,
                  outline: 0,
                  verticalAlign: "baseline",
                  background: "transparent",
                  color: "#010101",
                  fontSize: "12px",
                  lineHeight: "24px",
                  letterSpacing: "0.54px",
                  padding: "0 50px",
                  margin: upMd ? "50px 0 15px" : "10px 0 15px",
                  boxSizing: "border-box",
                }}
              >
                Clicking yes will save your choice in a cookie.<br />
                Warning: Cannabis products sold here can expose you to chemicals including Δ9 Tetrahydrocannabinol (Δ9-THC) and cannabis (marijuana) smoke, which are known to the State of California to cause cancer and birth defects or other reproductive harm. For more information, go to <a href="https://www.p65warnings.ca.gov/">www.P65Warnings.ca.gov</a>.<br />
              </p>
            </form>
          </div>
        ) : null
      }
    </>
  );
}

type MenuButtonProps = {
  title: string;
  logo: string | undefined;
  highlighted: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
};

function MenuButton({title, logo, highlighted, onClick}: MenuButtonProps) {
  return (
    <Button
      style={{
        color: "black",
        fontSize: "18px",
        lineHeight: "21px",
        fontFamily: "NoirStd-Medium",
        letterSpacing: "0.16em",
        justifyContent: "flex-end",
        paddingTop: "10px",
        marginRight: "25px",
        borderWidth: "1px",
        borderRadius: "0",
        borderColor: highlighted ? "black" : "transparent",
      }}
      endIcon={logo ? (
        <div
          style={{
            marginLeft: "10px",
            paddingBottom: "5px",
          }}
        >
          <Logo type={logo} />
        </div>
      ) : undefined}
      variant={"outlined"}
      onClick={onClick}
    >
      { title }
    </Button>
  );
}

function App() {
  const location = useLocation();
  const history = useHistory();

  const routeMatchHome = matchPath(location.pathname, {
    path: "/",
    exact: true,
  });
  const routeMatchGreenhouse = matchPath(location.pathname, {
    path: "/greenhouse",
    exact: true,
  });
  const routeMatchPlant = matchPath<{
    plantType: string,
  }>(location.pathname, {
    path: "/plant/:plantType",
    exact: true,
  });
  const routeMatchExperience = matchPath<{
    plantType: string,
  }>(location.pathname, {
    path: "/experience/:plantType",
    exact: true,
  });

  const viewportOverlayRef = useRef<HTMLDivElement>(null);
  const theme = useTheme();

  const [soundPlaying, setSoundPlaying] = useState(true);

  const cherryAkAudioRef = useRef<HTMLAudioElement>(null);
  const iceCreamCakeAudioRef = useRef<HTMLAudioElement>(null);
  const hellfireAudioRef = useRef<HTMLAudioElement>(null);

  useEffect(() => {
    if(!routeMatchHome && !routeMatchGreenhouse && !routeMatchPlant && !routeMatchExperience) history.push("/");
    if(routeMatchPlant && !["cherry-ak", "ice-cream-cake", "hellfire"].some(p => p === routeMatchPlant.params.plantType)) history.push("/");
    if(routeMatchExperience && !["cherry-ak", "ice-cream-cake", "hellfire"].some(p => p === routeMatchExperience.params.plantType)) history.push("/");
  }, [history, routeMatchHome, routeMatchGreenhouse, routeMatchPlant, routeMatchExperience]);

  const [ageConfirmed, setAgeConfirmed] = useState(false);

  const handleSoundPlaying = useCallback((newState: boolean, plantType?: string) => {
    if(!newState || !plantType) {
      setSoundPlaying(false);
      if(cherryAkAudioRef.current) {
        cherryAkAudioRef.current.currentTime = 0;
        cherryAkAudioRef.current.pause();
      }
      if(iceCreamCakeAudioRef.current) {
        iceCreamCakeAudioRef.current.currentTime = 0;
        iceCreamCakeAudioRef.current.pause();
      }
      if(hellfireAudioRef.current) {
        hellfireAudioRef.current.currentTime = 0;
        hellfireAudioRef.current.pause();
      }
    } else {
      if(cherryAkAudioRef.current && plantType === "cherry-ak" && cherryAkAudioRef.current.paused) {
        cherryAkAudioRef.current.currentTime = 0;
        cherryAkAudioRef.current.muted = false;
        cherryAkAudioRef.current.play()
          .then(() => {
            setSoundPlaying(true);
          })
          .catch(() => {
            setSoundPlaying(false);
          });
      }
      if(iceCreamCakeAudioRef.current && plantType === "ice-cream-cake" && iceCreamCakeAudioRef.current.paused) {
        iceCreamCakeAudioRef.current.currentTime = 0;
        iceCreamCakeAudioRef.current.muted = false;
        iceCreamCakeAudioRef.current.play()
          .then(() => {
            setSoundPlaying(true);
          })
          .catch(() => {
            setSoundPlaying(false);
          });
      }
      if(hellfireAudioRef.current && plantType === "hellfire" && hellfireAudioRef.current.paused) {
        hellfireAudioRef.current.currentTime = 0;
        hellfireAudioRef.current.muted = false;
        hellfireAudioRef.current.play()
          .then(() => {
            setSoundPlaying(true);
          })
          .catch(() => {
            setSoundPlaying(false);
          });
      }
    }
  }, []);

  useEffect(() => {
    if(!routeMatchExperience) {
      handleSoundPlaying(false);
    }
  }, [routeMatchExperience, handleSoundPlaying]);

  const [soundKickedOff, setSoundKickedOff] = useState(false);
  useEffect(() => {
    if(routeMatchExperience && !soundKickedOff) {
      handleSoundPlaying(false);
      handleSoundPlaying(true, routeMatchExperience.params.plantType);
      setSoundKickedOff(true);
    }
  }, [routeMatchExperience, soundKickedOff, handleSoundPlaying]);

  useEffect(() => {
    setInterval(() => {
      setSoundPlaying(
        (
          (cherryAkAudioRef.current && !cherryAkAudioRef.current.paused) ||
          (iceCreamCakeAudioRef.current && !iceCreamCakeAudioRef.current.paused) ||
          (hellfireAudioRef.current && !hellfireAudioRef.current.paused)
        ) ? true : false
      );
    }, 1000);
  }, []);

  const upSM = useMediaQuery(theme.breakpoints.up("sm"));
  const menuWidth = upSM ? "425px" : "100%";
  const [showMenu, setShowMenu] = useState(false);

  const menuRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const mouseDownListener = (e: MouseEvent) => {
      if(!showMenu || !e.target || !menuRef.current) return;
      if(!menuRef.current.contains(e.target as Node)) {
        setShowMenu(false);
      }
    };
    window.addEventListener("pointerdown", mouseDownListener, {capture: true});
    return () => {
      window.removeEventListener("pointerdown", mouseDownListener);
    };
  }, [showMenu]);

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "100%",
          position: "relative",
        }}
      >
        <div
          style={{
            flex: "1 0 0",
            position: "relative",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
            }}
          >
            <Home
              ageConfirmed={ageConfirmed}
              active={!!routeMatchHome}
              viewportOverlayRef={viewportOverlayRef}
            />
            <Greenhouse
              active={!!routeMatchGreenhouse}
              viewportOverlayRef={viewportOverlayRef}
              history={history}
            />
            <PlantViewer
              active={!!routeMatchPlant}
              viewportOverlayRef={viewportOverlayRef}
              history={history}
              routeMatchPlant={routeMatchPlant}
              handleSoundPlaying={handleSoundPlaying}
            />
            {
              allPlantTypes.map(p => (
                <React.Fragment
                  key={p.route}
                >
                  <Experience
                    active={!!routeMatchExperience && routeMatchExperience.params.plantType === p.route}
                    plantType={p.type}
                    viewportOverlayRef={viewportOverlayRef}
                    history={history}
                  />
                </React.Fragment>
              ))
            }
          </div>
          <div
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              zIndex: 1,
              pointerEvents: "none",
            }}
            ref={viewportOverlayRef}
          />
        </div>
      </div>
      <div
        style={{
          display: routeMatchHome ? "none" : "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          zIndex: 1,
          position: "absolute",
          top: 0,
          right: 0,
          left: 0,
          pointerEvents: "none",
        }}
      >
        {
          <IconButton
            onClick={() => {
              setShowMenu(true);
            }}
            style={{
              pointerEvents: "all",
            }}
          >
            <MenuIcon
              style={{
                color: theme.palette.text.primary,
                filter: "drop-shadow(0 0 2px black)",
              }}
            />
          </IconButton>
        }
        {
          routeMatchExperience ? (
            <IconButton
              onClick={() => {
                handleSoundPlaying(!soundPlaying, routeMatchExperience.params.plantType);
              }}
              style={{
                pointerEvents: "all",
              }}
            >
              {
                !soundPlaying ? (
                  <VolumeOffIcon
                    style={{
                      color: theme.palette.text.primary,
                      filter: "drop-shadow(0 0 2px black)",
                    }}
                  />
                ) : (
                  <VolumeUpIcon
                    style={{
                      color: theme.palette.text.primary,
                      filter: "drop-shadow(0 0 2px black)",
                    }}
                  />
                )
              }
            </IconButton>
          ) : (
            <div
              style={{
                width: "48px",
                height: "48px",
              }}
            />
          )
        }
      </div>
      <div
        ref={menuRef}
        style={{
          position: "absolute",
          top: 0,
          left: showMenu ? 0 : `-${menuWidth}`,
          bottom: 0,
          width: `${menuWidth}`,
          transition: "left 0.5s ease-in-out",
          backgroundColor: "#F9ED32",
          color: "white",
          overflow: "auto",
          zIndex: 2147483647,
          display: "flex",
          flexDirection: "column",
          paddingTop: "48px",
          justifyContent: "space-evenly",
          alignItems: "flex-end",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            zIndex: 1,
            position: "absolute",
            top: 0,
            right: 0,
            left: 0,
            pointerEvents: "none",
          }}
        >
          {
            <IconButton
              onClick={() => {
                setShowMenu(false);
              }}
              style={{
                pointerEvents: "all",
              }}
            >
              <CloseIcon />
            </IconButton>
          }
        </div>
        {
          [
            {
              title: "GREEN HOUSE 360",
              logo: undefined,
              plantType: undefined,
            },
            {
              title: "CHERRY AK",
              logo: "logoCherryAk",
              plantType: "cherry-ak",
            },
            {
              title: "HELLFIRE",
              logo: "logoHellfire",
              plantType: "hellfire",
            },
            {
              title: "ICE CREAM CAKE",
              logo: "logoIceCreamCake",
              plantType: "ice-cream-cake",
            },
          ].map(({title, logo, plantType}, i) => (
            <MenuButton
              key={`experience${i}`}
              title={title}
              logo={logo}
              highlighted={(routeMatchPlant && routeMatchPlant.params.plantType === plantType) || (routeMatchExperience && routeMatchExperience.params.plantType === plantType) ? true : false}
              onClick={() => {
                setShowMenu(false);
                setTimeout(() => {
                  if(plantType) {
                    history.push(`/plant/${plantType}`);
                  } else {
                    history.push(`/greenhouse`);
                  }
                }, 500);
              }}
            />
          ))
        }
        <img key="gradient" src={ghfGradient} alt="GHF Gradient" />
        {
          [
            {
              title: "GLASS.HOUSE.FARMS",
              logo: "instagram",
              url: "https://www.instagram.com/glass.house.farms/",
            },
            {
              title: "GLASSHOUSEFARMS.ORG",
              logo: "ghf",
              url: "https://www.glasshousefarms.org/",
            },
          ].map(({title, logo, url}, i) => (
            <MenuButton
              key={`link${i}`}
              title={title}
              logo={logo}
              highlighted={false}
              onClick={() => {
                window.open(url, "_blank");
                firebase.analytics().logEvent("external_link", {
                  target: logo,
                });
              }}
            />
          ))
        }
      </div>
      <audio
        style={{
          display: "none",
        }}
        autoPlay
        loop
        ref={cherryAkAudioRef}
        src={soundtrackCherryAk}
      />
      <audio
        style={{
          display: "none",
        }}
        autoPlay
        loop
        ref={iceCreamCakeAudioRef}
        src={soundtrackIceCreamCake}
      />
      <audio
        style={{
          display: "none",
        }}
        autoPlay
        loop
        ref={hellfireAudioRef}
        src={soundtrackHellfire}
      />
      <AgeGate21
        ageConfirmed={ageConfirmed}
        setAgeConfirmed={setAgeConfirmed}
      />
    </>
  );
}

export default App;
