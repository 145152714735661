import { CircularProgress, ThemeProvider } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { theme } from ".";
import firebase from 'firebase/app';

export const transitionTime = 1000;

export const useRoutesTransition = (active: boolean) => {
  const [state, setState] = useState<"hidden" | "visible" | "fade-in" | "fade-out">(active ? "visible" : "hidden");

  useEffect(() => {
    if(active && state === "fade-out") {
      setState("hidden");
      return;
    }
    if(active && state === "hidden") {
      setState("fade-in");
      return;
    }
    if(active && state === "fade-in") {
      setState("visible");
      return;
    }
    if(!active && state === "fade-in") {
      setState("visible");
      return;
    }
    if(!active && state === "visible") {
      setState("fade-out");
      return;
    }
    if(!active && state === "fade-out") {
      setTimeout(() => setState("hidden"), transitionTime);
      return;
    }
  }, [state, active]);

  return state;
};


type RouteWrapperProps = {
  active: boolean,
  children?: React.ReactNode,
  style?: React.CSSProperties,
};

export const RouteWrapper = ({active, children, style}: RouteWrapperProps) => {
  const state = useRoutesTransition(active);
  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        transition: `all ${transitionTime}ms`,
        transitionProperty: "opacity, transform",
        transform: ["visible", "fade-out"].some(s => s === state) ? "translate3d(0, 0, 0)" : "translate3d(0, 0, 0)",
        zIndex: ["fade-in", "visible"].some(s => s === state) ? 1 : 0,
        opacity: ["visible", "fade-out"].some(s => s === state) ? 1 : 0,
        pointerEvents: state === "hidden" ? "none" : undefined,
        ...style,
      }}
    >
      {
        state === "hidden" ? null : children
      }
    </div>
  );
}

type ViewportLoadingSpinnerProps = {
  viewportOverlayRef: React.RefObject<HTMLDivElement>;
};

export function ViewportLoadingSpinner({viewportOverlayRef}: ViewportLoadingSpinnerProps) {
  useEffect(() => {
    if(!viewportOverlayRef.current) return;
    const newNode = document.createElement("div");
    newNode.style.cssText = `
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    `;
    viewportOverlayRef.current.append(newNode);
    ReactDOM.render(
      <ThemeProvider theme={theme}>
        <CircularProgress
          color="primary"
        />
      </ThemeProvider>,
      newNode
    );
    return () => {
      newNode.remove();
    }
  }, [viewportOverlayRef]);

  return null;
}

export const backgroundColor = "black";

export const usePageView: (active: boolean, page_path: string, page_title: string) => void = (active: boolean, page_path: string, page_title: string) => {
  useEffect(() => {
    if(active) {
      firebase.analytics().logEvent("page_view", {
        page_path,
        page_title,
      });
    }
  }, [active, page_path, page_title]);
}

export const mapRange = (n: number, in_min: number, in_max: number, out_min: number, out_max: number) => {
  return (n - in_min) * (out_max - out_min) / (in_max - in_min) + out_min;
}

export function wrapAround(x: number, m: number) {
  return (x%m + m)%m;
}
