import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import * as THREE from 'three';
import { invalidate, useFrame, useThree } from 'react-three-fiber';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { DeviceOrientationControls } from 'three/examples/jsm/controls/DeviceOrientationControls';
import ReactDOM from 'react-dom';
import { Slider } from '@material-ui/core';
import * as CANNON from 'cannon-es';
import { useImperativeHandle } from 'react';

type TouchForwarderProps = {
  gl: THREE.WebGLRenderer,
  draggingMotionValue: boolean,
};

function TouchForwarder({gl, draggingMotionValue}: TouchForwarderProps) {
  const divRef = useRef<HTMLDivElement>(null);

  const getViewportTouch = (touches: TouchList, viewportDiv: HTMLDivElement) => {
    for(let t of touches) {
      if(t.target === viewportDiv) return t;
    }
  };

  const forwardEvent = useCallback((e: Event, newEvent?: Event) => {
    if(e.type === "pointerdown") e.preventDefault();
    const theNewEvent = newEvent ?? new (e as any).constructor(e.type, e);
    gl.domElement.dispatchEvent(theNewEvent);
  }, [gl]);

  useEffect(() => {
    if(!divRef.current) return;
    const d = divRef.current;
    const touchStartListener = (e: TouchEvent) => {
      const viewportTouch = getViewportTouch(e.touches, d);
      const theNewEvent = new (e as any).constructor(e.type, {
        ...e,
        touches: draggingMotionValue ? [
          viewportTouch,
        ] : e.touches,
        cancelable: true,
      }) as TouchEvent;
      forwardEvent(e, theNewEvent);
    };
    const touchMoveListener = (e: TouchEvent) => {
      const viewportTouch = getViewportTouch(e.touches, d);
      const theNewEvent = new (e as any).constructor(e.type, {
        ...e,
        touches: draggingMotionValue ? [
          viewportTouch,
        ] : e.touches,
        cancelable: true,
      }) as TouchEvent;
      forwardEvent(e, theNewEvent);
    };
    d.addEventListener("touchstart", touchStartListener);
    d.addEventListener("touchmove", touchMoveListener);
    return () => {
      d.removeEventListener("touchstart", touchStartListener);
      d.removeEventListener("touchmove", touchMoveListener);
    }
  }, [gl, draggingMotionValue, forwardEvent]);

  return (
    <div
      style={{
        flex: "1 0 0",
      }}
      ref={divRef}
      onPointerDown={e => forwardEvent(e.nativeEvent)}
      onContextMenu={e => forwardEvent(e.nativeEvent)}
      onWheel={e => forwardEvent(e.nativeEvent)}
      onTouchEnd={e => forwardEvent(e.nativeEvent)}
    />
  );
}

export type ControlsRef = PhysicsRef & {
  setCameraQuaternion: (quat: THREE.Quaternion) => void;
  createDeviceOrientationControls: () => void,
};

type DefaultControlsProps = {
  buttonContainer: React.RefObject<HTMLDivElement | null>;
  height?: number;
  move?: boolean,
  pauseMove?: boolean,
  obstacleGroup?: THREE.Object3D,
  enableZoom?: boolean,
  useDeviceControls: boolean,
  setUseDeviceControls: React.Dispatch<React.SetStateAction<boolean>>,
  autoRotate?: boolean;
}

const DefaultControls = React.forwardRef(({useDeviceControls, setUseDeviceControls, buttonContainer, height = 0, move = false, pauseMove = false, obstacleGroup, enableZoom = false, autoRotate = false}: DefaultControlsProps, ref: React.Ref<ControlsRef>) => {
  const physicsRef = useRef<PhysicsRef>(null);

  const { camera, gl } = useThree();

  useEffect(() => {
    camera.near = 0.05;
    camera.far = 50;
  }, [camera]);

  const orbitControlsCamera = useMemo(() => {
    const cam = new THREE.PerspectiveCamera();
    cam.position.set(-1, 0, 0);
    return cam;
  }, []);

  const orbitControls = useMemo(() => {
    const ctrls = new OrbitControls(orbitControlsCamera, gl.domElement);
    ctrls.enableDamping = true;
    ctrls.enableZoom = enableZoom;
    ctrls.addEventListener("change", () => invalidate());
    ctrls.minDistance = 0.5;
    ctrls.maxDistance = 1.5;
    ctrls.autoRotate = useDeviceControls ? false : autoRotate;
    ctrls.autoRotateSpeed = 0.25;
    return ctrls;
  }, [orbitControlsCamera, gl, enableZoom, autoRotate, useDeviceControls]);

  const deviceOrientationControlsCamera = useMemo(() => new THREE.PerspectiveCamera(), []);
  const deviceOrientationControlsRef = useRef<DeviceOrientationControls>();

  const [draggingMotionValue, setDraggingMotionValue] = useState(false);
  const [motionValueForward, setMotionValueForward] = useState(0);
  const [motionValueRight, setMotionValueRight] = useState(0);

  useFrame(() => {
    orbitControls.update();
    if(deviceOrientationControlsRef.current) deviceOrientationControlsRef.current.update();

    camera.quaternion.copy(new THREE.Quaternion());

    camera.quaternion.multiply(new THREE.Quaternion().setFromEuler(new THREE.Euler(0, orbitControls.getAzimuthalAngle(), 0)));
    
    if(useDeviceControls) camera.quaternion.multiply(deviceOrientationControlsCamera.quaternion);

    camera.quaternion.multiply(new THREE.Quaternion().setFromEuler(new THREE.Euler(orbitControls.getPolarAngle()-Math.PI/2, 0, 0)));

    if(enableZoom) (camera as THREE.PerspectiveCamera).fov = orbitControlsCamera.position.length()*75;
    camera.updateProjectionMatrix();
  }, -1);

  const createDeviceOrientationControls = useCallback(() => {
    deviceOrientationControlsRef.current = new DeviceOrientationControls(deviceOrientationControlsCamera);
    deviceOrientationControlsRef.current.addEventListener("change", e => {
      invalidate();
      const de = (e.target.deviceOrientation as DeviceOrientationEvent);
      if(de.alpha != null && de.beta != null && de.gamma != null) setUseDeviceControls(true);
    });
  }, [deviceOrientationControlsCamera]); // eslint-disable-line

  useEffect(() => {
    createDeviceOrientationControls();
  }, [createDeviceOrientationControls]);

  const [initialCamPosSet, setInitialCamPosSet] = useState(false);
  useEffect(() => {
    if(initialCamPosSet) return;
    setInitialCamPosSet(true);
    if(!move) camera.position.set(0, 0, 0);
  }, [move, camera, initialCamPosSet]);

  useImperativeHandle(ref, () => ({
    setPlayerPosition: physicsRef.current?.setPlayerPosition,
    setCameraQuaternion: (quat: THREE.Quaternion) => {
      orbitControlsCamera.position.copy(new THREE.Vector3(0, 0, 1).applyQuaternion(quat));
      orbitControls.update();
    },
    createDeviceOrientationControls,
  }));

  useEffect(() => {
    if(!buttonContainer.current) throw new Error("Button container must be specified!");

    ReactDOM.render(
      (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          }}
        >
          <TouchForwarder
            gl={gl}
            draggingMotionValue={draggingMotionValue}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
            }}
          >
            <div
              style={{
                height: "50%",
              }}
            >
              {
                move ? (
                  <Slider
                    value={motionValueForward}
                    min={-1}
                    max={1}
                    step={0.0001}
                    orientation="vertical"
                    onChange={(e, v) => {
                      if(!draggingMotionValue) {
                        setDraggingMotionValue(true);
                      }
                      setMotionValueForward(v as number);
                    }}
                    onChangeCommitted={() => {
                      setDraggingMotionValue(false);
                      setMotionValueForward(0);
                    }}
                    style={{
                      color: "white",
                      filter: "drop-shadow(0 0 2px black)",
                    }}
                  />
                ) : null
              }
            </div>
          </div>
        </div>
      ),
      buttonContainer.current
    );
  }, [buttonContainer, createDeviceOrientationControls, useDeviceControls, motionValueForward, draggingMotionValue, gl, move]);

  useEffect(() => {
    setMotionValueForward(0);
    setMotionValueRight(0);
    invalidate();
  }, [pauseMove]);

  useEffect(() => {
    const keyDownListener = (e: KeyboardEvent) => {
      if(pauseMove) return;
      if(e.code === "KeyW" || e.code === "ArrowUp") {
        setMotionValueForward(0.75);
        invalidate();
      }
      if(e.code === "KeyS" || e.code === "ArrowDown") {
        setMotionValueForward(-0.75);
        invalidate();
      }
      if(e.code === "KeyA" || e.code === "ArrowLeft") {
        setMotionValueRight(-0.75);
        invalidate();
      }
      if(e.code === "KeyD" || e.code === "ArrowRight") {
        setMotionValueRight(0.75);
        invalidate();
      }
    };
    const keyUpListener = (e: KeyboardEvent) => {
      if(pauseMove) return;
      if(
        e.code === "KeyW" || e.code === "ArrowUp" ||
        e.code === "KeyS" || e.code === "ArrowDown"
      ) {
        setMotionValueForward(0);
        invalidate();
      }
      if(
        e.code === "KeyA" || e.code === "ArrowLeft" ||
        e.code === "KeyD" || e.code === "ArrowRight"
      ) {
        setMotionValueRight(0);
        invalidate();
      }
    };
    window.addEventListener("keydown", keyDownListener);
    window.addEventListener("keyup", keyUpListener);
    return () => {
      window.removeEventListener("keydown", keyDownListener);
      window.removeEventListener("keyup", keyUpListener);
    }
  }, [pauseMove]);

  return move ? (
    <Physics
      obstacleGroup={obstacleGroup}
      motionValueForward={motionValueForward}
      motionValueRight={motionValueRight}
      ref={physicsRef}
    />
  ) : null;
});
export default DefaultControls;

type PhysicsRef = {
  setPlayerPosition: ((pos: THREE.Vector3) => void) | undefined;
};

type PhysicsProps = {
  obstacleGroup?: THREE.Object3D,
  height?: number,
  motionValueForward: number,
  motionValueRight: number,
};

const Physics = React.forwardRef(({obstacleGroup, height = 0.5, motionValueForward, motionValueRight}: PhysicsProps, ref: React.Ref<PhysicsRef>) => {
  const world = useMemo(() => {
    const world = new CANNON.World();
    var solver = new CANNON.GSSolver();
    var split = true;
    if(split)
        world.solver = new CANNON.SplitSolver(solver);
    else
        world.solver = solver;
    return world;
  }, []);
  const physicsMaterial = useMemo(() => {
    const physicsMaterial = new CANNON.Material("slipperyMaterial");
    var physicsContactMaterial = new CANNON.ContactMaterial(
      physicsMaterial,
      physicsMaterial,
      {
        friction: 0.4,
      }
    );
    world.addContactMaterial(physicsContactMaterial);
    return physicsMaterial;
  }, [world]);

  const sphereRadius = .2;
  const sphereShape = useMemo(() => {
    var mass = 5;
    const sphereShape = new CANNON.Sphere(sphereRadius);
    const sphereBody = new CANNON.Body({ mass: mass, material: physicsMaterial });
    sphereBody.addShape(sphereShape);
    sphereBody.position.set(0, sphereRadius, 0);
    sphereBody.linearDamping = 0.9999;
    world.addBody(sphereBody);
    return sphereShape;
  }, [physicsMaterial, world]);

  useImperativeHandle(ref, () => ({
    setPlayerPosition: (pos: THREE.Vector3) => {
      const b = sphereShape.body;
      if(!b) return;
      b.position.x = pos.x;
      b.position.y = pos.y + sphereRadius - height;
      b.position.z = pos.z;
    },
  }));

  useEffect(() => {
    if(!obstacleGroup) return;
    for(let c of obstacleGroup.children) {
      const owScale = c.getWorldScale(new THREE.Vector3());
      var oShape = new CANNON.Box(new CANNON.Vec3(Math.abs(owScale.x), Math.abs(owScale.y), Math.abs(owScale.z)));
      var oBody = new CANNON.Body({ mass: 0, material: physicsMaterial });
      oBody.addShape(oShape);
      const owQuat = c.getWorldQuaternion(new THREE.Quaternion());
      oBody.quaternion.copy(new CANNON.Quaternion(owQuat.x, owQuat.y, owQuat.z, owQuat.w));
      const owPos = c.getWorldPosition(new THREE.Vector3());
      oBody.position.copy(new CANNON.Vec3(owPos.x, owPos.y, owPos.z));
      world.addBody(oBody);
    }
  }, [obstacleGroup, physicsMaterial, world]);

  const { camera } = useThree();

  useFrame((state, delta) => {
    if(!sphereShape.body) return;
    const cameraLook = new THREE.Vector3(0, 0, -1).applyQuaternion(camera.quaternion);
    cameraLook.y = 0;
    cameraLook.normalize();
    const cameraRight = new THREE.Vector3(1, 0, 0).applyQuaternion(camera.quaternion);
    cameraRight.y = 0;
    cameraRight.normalize();
    const fac = 50;
    const combinedForce = cameraLook.clone().multiplyScalar(motionValueForward*fac).add(cameraRight.clone().multiplyScalar(motionValueRight*fac));
    sphereShape.body.applyForce(new CANNON.Vec3(combinedForce.x, combinedForce.y, combinedForce.z));

    world.step(delta);

    camera.position.set(sphereShape.body.position.x, sphereShape.body.position.y - sphereRadius + height, sphereShape.body.position.z);
  });

  return null;
});
