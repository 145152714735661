import React, { Suspense, useEffect, useRef, useState } from 'react';
import { Canvas, invalidate, useFrame, useThree } from 'react-three-fiber';

import * as THREE from 'three';
import DefaultControls, { ControlsRef } from './DefaultControls';
import { backgroundColor, RouteWrapper, usePageView } from './common';
import Markers, { MarkerData } from './Markers';
// @ts-ignore
import markerModelFile from './media/overview.glb';
import { BrowserRouter } from 'react-router-dom';
import * as H from 'history';
import Photosphere, { PhotosphereType } from './Photosphere';
import greehousePhotosphere_ from './media/greenhouseImage.jpg';
import { Overlay360Greenhouse } from './Overlay360';
import { useGLTF } from '@react-three/drei';

const greehousePhotosphere: PhotosphereType = greehousePhotosphere_ as any;

const markerData: {[key: string]: MarkerData} = {
  "cherry-ak": {
    title: "Cherry AK",
    icon: "logoCherryAk",
    route: "cherry-ak",
  },
  "ice-cream-cake": {
    title: "Ice Cream Cake",
    icon: "logoIceCreamCake",
    route: "ice-cream-cake",
  },
  "hellfire": {
    title: "Hellfire",
    icon: "logoHellfire",
    route: "hellfire",
  },
};

type PhotosphereSceneProps = {
  history: H.History;
  loaded: boolean;
  setLoaded: React.Dispatch<React.SetStateAction<boolean>>;
};

function PhotosphereScene({history, loaded, setLoaded}: PhotosphereSceneProps) {
  const { camera } = useThree();
  const sceneRef = useRef<THREE.Scene>();
  const [selectedMarker, setSelectedMarker] = useState<string>();

  useEffect(() => {
    if(selectedMarker) {
      history.push(`/plant/${markerData[selectedMarker].route}`);
      setSelectedMarker(undefined);
    }
  }, [selectedMarker, history]);

  useFrame(({ gl }) => {
    if(!sceneRef.current) return;
    invalidate();

    gl.autoClear = true;
    gl.render(sceneRef.current, camera);
  }, 1);

  const markerModel = useGLTF(markerModelFile, false);

  return (
    <>
      <scene
        ref={sceneRef}
      >
        <Photosphere
          photosphere={greehousePhotosphere}
          radius={1}
          position={new THREE.Vector3(0, 0, 0)}
          quaternion={new THREE.Quaternion().identity()}
          transparent={false}
          setLoaded={setLoaded}
        />
        {
          loaded ? (
            <Markers
              markerModel={markerModel}
              markerData={markerData}
              selectedMarker={selectedMarker}
              setSelectedMarker={setSelectedMarker}
              maxMarkerSize={200}
            />
          ) : null
        }
      </scene>
    </>
  );
}

type GreenhouseProps = {
  active: boolean,
  viewportOverlayRef: React.RefObject<HTMLDivElement>,
  history: H.History,
};

export default function Greenhouse({active, viewportOverlayRef, history}: GreenhouseProps) {
  const controlsButtonContainerRef = useRef<HTMLDivElement>(null);

  const controlsRef = useRef<ControlsRef>(null);
  const [useDeviceControls, setUseDeviceControls] = useState(false);

  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if(!loaded || !controlsRef.current) return;
    controlsRef.current.setCameraQuaternion(new THREE.Quaternion().setFromEuler(new THREE.Euler(0, -Math.PI/2 + 0.22, 0)));
  }, [loaded]);

  usePageView(active, "/greenhouse", "Greenhouse");

  return (
    <RouteWrapper
      active={active}
      style={{
        backgroundColor,
      }}
    >
      <div
        style={{
          width: "100%",
          height: "100%",
        }}
      >
        <Canvas
          invalidateFrameloop
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          }}
        >
          <BrowserRouter>
            <Suspense
              fallback={null}
            >
              <DefaultControls
                ref={controlsRef}
                buttonContainer={controlsButtonContainerRef}
                enableZoom
                useDeviceControls={useDeviceControls}
                setUseDeviceControls={setUseDeviceControls}
                autoRotate
              />
              <PhotosphereScene
                history={history}
                loaded={loaded}
                setLoaded={setLoaded}
              />
            </Suspense>
          </BrowserRouter>
        </Canvas>
        <div
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          }}
          ref={controlsButtonContainerRef}
        />
      </div>
      <Overlay360Greenhouse
        controlsRef={controlsRef}
        useDeviceControls={useDeviceControls}
        experienceName="GREENHOUSE"
        loaded={loaded}
      />
    </RouteWrapper>
  );
}
