import { Button, Typography, useMediaQuery } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { backgroundColor, mapRange, RouteWrapper, transitionTime, usePageView } from './common';
import homeBackgroundImage from './media/homeBackground.jpg';
// @ts-ignore
import introVideo from './media/introVideo.mp4';
import { useHistory } from 'react-router-dom';
import { useRef } from 'react';
import { animated, useSpring } from 'react-spring';
import * as easings from 'd3-ease';
import delay from 'delay';
import ghfLogo from './media/ghfLogo.svg';
import { useCookies } from 'react-cookie';
import { DoubleArrow as DoubleArrowIcon } from '@material-ui/icons';
import { theme } from '.';
import firebase from 'firebase/app';

type HomeProps = {
  active: boolean,
  viewportOverlayRef: React.RefObject<HTMLDivElement>,
  ageConfirmed: boolean;
};

export default function Home({active, viewportOverlayRef, ageConfirmed}: HomeProps) {
  const history = useHistory();

  const [videoPlaying, setVideoPlaying] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);

  const [{age_gate}] = useCookies(['age_gate']);

  const [entering, setEntering] = useState(false);
  const [{
    appearFade,
    sunFade,
    ghfLogoFade,
    bgImageFade,
    buttonFade,
  }] = useSpring({
    from: {
      appearFade: 0,
      sunFade: 0,
      ghfLogoFade: 0,
      bgImageFade: 0,
      buttonFade: 0,
    },
    to: async next => {
      if(!active) return;
      await delay(1000);
      await next({
        appearFade: 1,
        config: {
          easing: easings.easeQuadOut,
          duration: 2000,
        },
      });
      if(!(age_gate === "21" || ageConfirmed)) return;
      if(!entering) {
        await delay(500);
        await next({
          sunFade: 1,
          config: {
            easing: easings.easeQuadInOut,
            duration: 1000,
          },
        });
        await next({
          ghfLogoFade: 1,
          config: {
            easing: easings.easeQuadInOut,
            duration: 1000,
          },
        });
        await delay(500);
        next({
          bgImageFade: 1,
          config: {
            easing: easings.easeQuadInOut,
            duration: 2000,
          },
        }).catch(() => {});
        await delay(1000);
        await next({
          buttonFade: 1,
          config: {
            easing: easings.easeQuadInOut,
            duration: 1000,
          },
        });
      }
      if(entering) {
        next({
          buttonFade: 2,
          config: {
            easing: easings.easeQuadInOut,
            duration: 500,
          },
        }).catch(() => {});
        await delay(250);
        next({
          ghfLogoFade: 2,
          config: {
            easing: easings.easeQuadInOut,
            duration: 500,
          },
        }).catch(() => {});
        await delay(1000);
        next({
          bgImageFade: 2,
          config: {
            easing: easings.easeQuadInOut,
            duration: 4000,
          },
        }).catch(() => {});
        await delay(1000);
        setVideoPlaying(true);
        setEntering(false);
      }
    },
  }, [entering, active, age_gate, ageConfirmed]);

  useSpring({
    volume: active ? 1 : 0,
    onChange: val => {
      if(!videoRef.current) return;
      videoRef.current.volume = val.volume;
    },
  });

  useEffect(() => {
    if(!active) {
      setTimeout(() => {
        if(!videoRef.current) return;
        setVideoPlaying(false);
        videoRef.current.currentTime = 0;
        videoRef.current.pause();
      }, transitionTime);
    }
  }, [active]);

  const largeScreen = useMediaQuery(theme.breakpoints.up('md'));
  const sunDiameter = largeScreen ? 600 : 325;

  usePageView(active, "/", "Home");

  return (
    <RouteWrapper
      active={active}
    >
      {
        <>
          <div
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              overflow: "hidden",
              backgroundColor,
            }}
          >
            <animated.div
              style={{
                width: "100%",
                height: "100%",
                // transform: bgImageFade.to(x => `translate3d(0, ${-x/2.5*100}%, 0)`),
                opacity: appearFade.to(x => x <= 1 ? `${x}` : `${2-x}`),
              }}
            >
              <animated.div
                style={{
                  transform: bgImageFade.to(x => x <= 1 ? `scale(${mapRange(x, 0, 1, 2, 1)})` : `scale(${mapRange(x, 1, 2, 1, 4)})`),
                  backgroundImage: `url(${homeBackgroundImage})`,
                  backgroundSize: "cover",
                  backgroundPositionX: "center",
                  backgroundPositionY: "40%",
                  backgroundRepeat: "no-repeat",
                  width: "100%",
                  height: "100%",
                  // marginLeft: "-50%",
                  transition: "margin-top 5s",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: 'flex-end',
                  alignItems: "center",
                }}
              >
                <animated.div
                  style={{
                    position: "absolute",
                    top: "37.5%",
                    marginLeft: "auto",
                    marginRight: "auto",
                    opacity: ghfLogoFade.to(x => x <= 1 ? `${x}` : `${2-x}`),
                    backgroundImage: `url(${ghfLogo})`,
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    backgroundPositionX: "center",
                    backgroundPositionY: "top",
                    minWidth: "40px",
                    width: "3.5%",
                    maxWidth: "200px",
                    height: "100%",
                  }}
                />
              </animated.div>
            </animated.div>
          </div>
          <animated.div
            style={{
              position: "absolute",
              marginTop: sunFade.to(x => `${mapRange(x, 0, 1, -sunDiameter, -sunDiameter/ (largeScreen ? 1.45 : 1.7))}px`),
              opacity: appearFade.to(x => x <= 1 ? `${x}` : `${2-x}`),
              left: `calc(50% - ${sunDiameter / 2}px)`,
              width: `${sunDiameter}px`,
            }}
          >
            <div
              style={{
                backgroundColor: "#F9EC31",
                borderRadius: "50%",
                width: "100%",
                height: "100%",
                paddingTop: "100%",
                position: "relative",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  bottom: 0,
                  left: 0,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <svg>
                  <text x="50%" y={largeScreen ? "-20%" : "15%"} dominantBaseline="middle" textAnchor="middle">
                    <tspan x="50%" dy="1.2em" fontSize={largeScreen ? "80px" : "50px"} fontFamily="NoirStd-Medium" letterSpacing="10px">
                      FARM
                    </tspan>
                    <tspan x="50%" dy="3em" fontSize={largeScreen ? "16px" : "12px"} fontFamily="NoirStd-Medium" letterSpacing="2px">
                      EXPERIENCE
                    </tspan>
                  </text>
                </svg>
              </div>
            </div>
          </animated.div>
          <animated.div
            style={{
              position: "absolute",
              bottom: "10%",
              marginLeft: "auto",
              marginRight: "auto",
              transform: buttonFade.to(x => x <= 1 ? `scale(${x})` : ""),
              opacity: buttonFade.to(x => x <= 1 ? `${x}` : `${2-x}`),
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              style={{
                padding: "6px 50px",
                fontWeight: "bold",
                fontFamily: "NoirStd-Medium",
                fontSize: "12px",
                letterSpacing: "0.215em",
                borderRadius: 0,
              }}
              variant="contained"
              color="primary"
              onClick={() => {
                firebase.analytics().logEvent("enter_app");
                setEntering(true);
                videoRef.current?.play().catch(() => {});
              }}
            >
              ENTER
            </Button>
          </animated.div>
          <div
            style={{
              backgroundColor,
              position: "absolute",
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              opacity: videoPlaying ? 1 : 0,
              pointerEvents: videoPlaying ? "all" : "none",
              transition: `all ${transitionTime}ms`,
            }}
          >
            <div
              style={{
                width: "100%",
                flex: "1 0 0",
                position: "relative",
              }}
            >
              <video
                ref={videoRef}
                src={introVideo}
                style={{
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
                onEnded={() => history.push("/greenhouse")}
                playsInline
              />
              <div
                style={{
                  position: "absolute",
                  bottom: 0,
                  right: 0,
                }}
              >
                <Button
                  color="primary"
                  onClick={() => history.push("/greenhouse")}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    SKIP
                  </Typography>
                  <Typography
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <DoubleArrowIcon fontSize="large" />
                  </Typography>
                </Button>
              </div>
            </div>
          </div>
        </>
      }
    </RouteWrapper>
  );
}
