import React, { Suspense, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { Canvas, invalidate, useFrame, useLoader, useThree } from 'react-three-fiber';
import * as THREE from 'three';
import { SVGLoader } from 'three/examples/jsm/loaders/SVGLoader';
import circleImg from './media/turnTableArrows.svg';
// @ts-ignore
import markerModelIceCreamCakeFile from './media/turntableImages/iceCreamCake/model.glb';
// @ts-ignore
import markerModelCherryAkFile from './media/turntableImages/cherryAk/model.glb';
// @ts-ignore
import markerModelHellfireFile from './media/turntableImages/hellfire/model.glb';

// @ts-ignore
import cherryAkAtlas_ from './media/turntableImages/cherryAk/index';
// @ts-ignore
import iceCreamCakeAtlas_ from './media/turntableImages/iceCreamCake/index';
// @ts-ignore
import hellfireAtlas_ from './media/turntableImages/hellfire/index';

import { useGLTF } from '@react-three/drei';
import { Button, IconButton, Typography, useMediaQuery, useTheme } from '@material-ui/core';

import { backgroundColor, mapRange, RouteWrapper, usePageView, ViewportLoadingSpinner, wrapAround } from './common';
import { createAsset } from 'use-asset';
import Markers, { MarkerData } from './Markers';
// @ts-ignore
import cherryAkDetailMovie from './media/detailImages/cherryAk/Cherry Zoom 1080p.mp4';
// @ts-ignore
import iceCreamCakeDetailMovie from './media/detailImages/iceCreamCake/Ice Cream Zoom 1080p.mp4';
// @ts-ignore
import hellfireDetailMovie from './media/detailImages/hellfire/Hellfire Zoom 1080p.mp4';
import terpenesCherryAk from './media/terpenesCherryAk.jpg';
import terpenesHellfire from './media/terpenesHellfire.jpg';
import terpenesIceCreamCake from './media/terpenesIceCreamCake.jpg';
import cherryAkBullet1 from './media/GHF_TERP_ICONS_Myrcene.svg';
import cherryAkBullet2 from './media/GHF_TERP_ICONS_Beta-Caryophyllene.svg';
import cherryAkBullet3 from './media/GHF_TERP_ICONS_Pinene.svg';
import hellfireBullet1 from './media/GHF_TERP_ICONS_Limonene.svg';
import hellfireBullet2 from './media/GHF_TERP_ICONS_Beta-Caryophyllene.svg';
import hellfireBullet3 from './media/GHF_TERP_ICONS_Linalool.svg';
import iceCreamCakeBullet1 from './media/GHF_TERP_ICONS_Limonene.svg';
import iceCreamCakeBullet2 from './media/GHF_TERP_ICONS_Beta-Caryophyllene.svg';
import iceCreamCakeBullet3 from './media/GHF_TERP_ICONS_Humulene.svg';
import * as H from 'history';
import { match } from 'react-router-dom';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import CloseIcon from '@material-ui/icons/Close';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, {Pagination} from 'swiper';
import 'swiper/swiper-bundle.css';
import './Plant.css';
import cherryAkLarge from './media/cherryAkLarge.jpg';
import iceCreamCakeLarge from './media/iceCreamCakeLarge.jpg';
import hellfireLarge from './media/hellfireLarge.jpg';
import infoBgCherryAkSmallScreen from './media/infoBgCherryAkSmallScreen.svg';
import infoBgIceCreamCakeSmallScreen from './media/infoBgIceCreamCakeSmallScreen.svg';
import infoBgHellfireSmallScreen from './media/infoBgHellfireSmallScreen.svg';
import infoBgCherryAkLargeScreen from './media/infoBgCherryAkLargeScreen.svg';
import infoBgIceCreamCakeLargeScreen from './media/infoBgIceCreamCakeLargeScreen.svg';
import infoBgHellfireLargeScreen from './media/infoBgHellfireLargeScreen.svg';
import useResizeObserver from 'use-resize-observer';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { useSpring } from 'react-spring';

SwiperCore.use([Pagination]);

type Atlas = {
  atlases: string[],
  numHImages: number,
  numVImages: number,
  numTotalImages: number,
  aspect: number,
};

const cherryAkAtlas: Atlas = cherryAkAtlas_;
const iceCreamCakeAtlas: Atlas = iceCreamCakeAtlas_;
const hellfireAtlas: Atlas = hellfireAtlas_;

const turntableAsset = createAsset<THREE.Texture[], [atlases: string[], gl: THREE.WebGLRenderer]>(
  (atlases, gl) => new Promise(async res => {
    const loader = new THREE.TextureLoader();
    const promises = atlases.map(
      a => new Promise<THREE.Texture>((resolve, reject) => {
        loader.load(a, map => {
          map.encoding = THREE.sRGBEncoding;
          resolve(map);
        });
      })
    );
    const maps = await Promise.all(promises);
    res(maps);
  })
);

const markerDataIceCreamCake: {[key: string]: MarkerData} = {
  information: {
    title: "Information",
    icon: "information",
  },
  details: {
    title: "Details",
    icon: "details",
  },
};

const markerDataCherryAk: {[key: string]: MarkerData} = {
  information: {
    title: "Information",
    icon: "information",
  },
  details: {
    title: "Details",
    icon: "details",
  },
};

const markerDataHellfire: {[key: string]: MarkerData} = {
  information: {
    title: "Information",
    icon: "information",
  },
  details: {
    title: "Details",
    icon: "details",
  },
};

export const plantTitles: {[key: string]: {
  title: string;
  subtitle: string;
}} = {
  "cherry-ak": {
    title: "CHERRY AK",
    subtitle: "SATIVA DOMINANT",
  },
  "ice-cream-cake": {
    title: "ICE CREAM CAKE",
    subtitle: "INDICA DOMINANT",
  },
  "hellfire": {
    title: "HELLFIRE",
    subtitle: "INDICA DOMINANT",
  },
};
export const plants = Object.keys(plantTitles);

export const plantColors: {[key: string]: string} = {
  "cherry-ak": "#F9EC31",
  "ice-cream-cake": "#02A7E7",
  "hellfire": "#02A7E7",
};

export type TurntableViewerRef = {
  setPosition: (pos: THREE.Vector3) => void;
  setAlpha: (val: number) => void,
};

type TurntableViewerProps = {
  active: boolean;
  selectedMarker: string | undefined;
  setSelectedMarker: React.Dispatch<React.SetStateAction<string | undefined>>;
  markerData: {[key: string]: MarkerData};
  plantColor: string;
  onLoad: () => void;
};

const TurntableViewer = React.forwardRef(({active, selectedMarker, setSelectedMarker, plantAtlas, markerModelFile, markerData, plantColor, onLoad}: (TurntableViewerProps & { plantAtlas: Atlas, markerModelFile: string }), ref: React.Ref<TurntableViewerRef>) => {
  const { gl } = useThree();

  useImperativeHandle(ref, () => ({
    setPosition: pos => {
      if(!groupRef.current) return;
      groupRef.current.position.copy(pos);
    },
    setAlpha: val => {
      if(!objRef.current) return;
      const mat = objRef.current.material as THREE.ShaderMaterial;
      if(!mat.uniforms) return;
      mat.uniforms.opacity.value = val;
    },
  }));

  const orbitControlsCamera = useMemo(() => {
    if(!active) return undefined;
    const cam = new THREE.PerspectiveCamera();
    cam.position.set(0, 0, 1);
    return cam;
  }, [active]);

  const orbitControls = useMemo(() => {
    if(!orbitControlsCamera) return undefined;
    const ctrls = new OrbitControls(orbitControlsCamera, gl.domElement);
    ctrls.enableDamping = true;
    ctrls.enableZoom = false;
    ctrls.addEventListener("change", () => invalidate());
    ctrls.minDistance = 0.5;
    ctrls.maxDistance = 1.5;
    return ctrls;
  }, [orbitControlsCamera, gl]);

  const theme = useTheme();
  const veryLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));
  const [mat, setMat] = useState<THREE.ShaderMaterial>();
  const maps = turntableAsset.read(plantAtlas.atlases, gl);
  useEffect(() => {
    if(!active && mat && !veryLargeScreen) {
      for(let m of maps) {
        m.dispose();
      }
      setMat(undefined);
    }
    if(!active && !veryLargeScreen) return;
    (async () => {
      const material = new THREE.ShaderMaterial({
        lights: true,
        vertexShader: THREE.ShaderLib.standard.vertexShader,
        transparent: true,
        fragmentShader:
          `
            uniform float atlasIndex;
            uniform float opacity;
            uniform sampler2D maps[${plantAtlas.atlases.length}];
            varying vec2 vUv;

            float mapRange(float value, float inMin, float inMax, float outMin, float outMax) {
              return outMin + (outMax - outMin) * (value - inMin) / (inMax - inMin);
            }

            float modI(in float a, in float b) {
              float m=a-floor((a+0.5)/b)*b;
              return floor(m+0.5);
            }

            vec4 getColor(in int globalIndex) {
              int mapIndex = int(floor(float(globalIndex) / (float(${plantAtlas.numHImages}) * float(${plantAtlas.numVImages}))));
              int localIndex = globalIndex - mapIndex * ${plantAtlas.numHImages} * ${plantAtlas.numVImages};
              int localHIndex = int(modI(float(localIndex), float(${plantAtlas.numHImages})));
              int localVIndex = int(floor(float(localIndex)/float(${plantAtlas.numHImages})));
              vec4 col;
              ` + Array.from(Array(plantAtlas.atlases.length).keys()).reduce((prev, _, i) => prev + `
              ${i !== 0 ? "else " : ""}if (mapIndex == ${i}) {
                col = texture2D(maps[${i}], vUv*vec2(1.0/float(${plantAtlas.numHImages}), 1.0/float(${plantAtlas.numVImages}))+vec2(float(localHIndex)/float(${plantAtlas.numHImages}), 1.0-((1.0+float(localVIndex))/float(${plantAtlas.numVImages}))));
              }
              `, "") + `
              return col;
            }

            int getRevolvingIndex(in float atlasIndex) {
              return int(modI(floor(atlasIndex), float(${plantAtlas.numTotalImages})));
            }

            void main() {
              gl_FragColor = getColor(getRevolvingIndex(atlasIndex)) * vec4(1.0, 1.0, 1.0, ${veryLargeScreen ? "opacity" : 1.0});
            }
        `,
        uniforms: {
          ...THREE.ShaderLib.standard.uniforms,
          maps: { value: maps },
          atlasIndex: { value: 0 },
          opacity: { value: 0, },
        },
        defines: {
          USE_UV: "",
        },
      });
      material.transparent = true;
      setMat(material);
    })();
  }, [maps, plantAtlas, active, veryLargeScreen]); // eslint-disable-line

  useEffect(() => {
    if(veryLargeScreen) {
      onLoad();
    } else {
      if(mat) {
        onLoad();
      }
    }
  }, [veryLargeScreen, onLoad, mat]);

  const groupRef = useRef<THREE.Group>();
  const objRef = useRef<THREE.Mesh>();

  useFrame(({ gl }) => {
    if(mat && orbitControls) {
      let atlasIndex = active ? Math.round(-plantAtlas.numTotalImages * orbitControls.getAzimuthalAngle() / (2 * Math.PI)) : (Math.PI / 2) / (2 * Math.PI) -1;
      if(active) {
        (gltf.scene.children.find(o => o.name === "markers") as THREE.Object3D).rotation.y = active ? -orbitControls.getAzimuthalAngle() : 0;
        while(atlasIndex < 0) atlasIndex += plantAtlas.numTotalImages;
        mat.uniforms.atlasIndex.value = -atlasIndex;
      }
    }
  }, 2);

  const gltf = useGLTF(markerModelFile);

  useEffect(() => {
    if(!objRef.current) return;
    objRef.current.material = mat as any;
    if(!mat) return;
    (objRef.current.material as any).needsUpdate = true;
  }, [mat]);

  const o = useMemo(() => (gltf.scene.children.find(o => o.name === "canvas") as THREE.Mesh).clone(), [gltf]);

  return mat ? (
    <>
      <group
        ref={groupRef}
        visible={!!mat}
      >
        <group>
          <primitive
            object={o}
            ref={objRef}
          />
        </group>
        {
          active ? (
            <Markers
              markerModel={gltf}
              markerData={markerData}
              selectedMarker={selectedMarker}
              setSelectedMarker={setSelectedMarker}
              scale={0.75}
              color={plantColor}
            />
          ) : null
        }
      </group>
    </>
  ) : null;
});

function TurntableViewerIceCreamCake({active, selectedMarker, setSelectedMarker, plantColor, plantRef, onLoad}: TurntableViewerProps & {plantRef: React.RefObject<TurntableViewerRef>}) {
  return (
    <TurntableViewer
      ref={plantRef}
      active={active}
      selectedMarker={selectedMarker}
      setSelectedMarker={setSelectedMarker}
      plantAtlas={iceCreamCakeAtlas}
      markerModelFile={markerModelIceCreamCakeFile}
      markerData={markerDataIceCreamCake}
      plantColor={plantColor}
      onLoad={onLoad}
    />
  );
}

function TurntableViewerCherryAk({active, selectedMarker, setSelectedMarker, plantColor, plantRef, onLoad}: TurntableViewerProps & {plantRef: React.RefObject<TurntableViewerRef>}) {
  return (
    <TurntableViewer
      ref={plantRef}
      active={active}
      selectedMarker={selectedMarker}
      setSelectedMarker={setSelectedMarker}
      plantAtlas={cherryAkAtlas}
      markerModelFile={markerModelCherryAkFile}
      markerData={markerDataCherryAk}
      plantColor={plantColor}
      onLoad={onLoad}
    />
  );
}

function TurntableViewerHellfire({active, selectedMarker, setSelectedMarker, plantColor, plantRef, onLoad}: TurntableViewerProps & {plantRef: React.RefObject<TurntableViewerRef>}) {
  return (
    <TurntableViewer
      ref={plantRef}
      active={active}
      selectedMarker={selectedMarker}
      setSelectedMarker={setSelectedMarker}
      plantAtlas={hellfireAtlas}
      markerModelFile={markerModelHellfireFile}
      markerData={markerDataHellfire}
      plantColor={plantColor}
      onLoad={onLoad}
    />
  );
}

type InformationProps = {
  text: JSX.Element;
  header1: string;
  header2: string;
  plantColor: string;
  infoItems: {
    title: string;
    description: string;
    bullet: string;
  }[];
  description: JSX.Element;
  largeImage: string;
  macroImage: string;
  infoBgSmallScreen: string;
  infoBgLargeScreen: string;
  bottomBarHeight: number;
};

function Information({text, header1, header2, plantColor, infoItems, description, largeImage, macroImage, infoBgSmallScreen, infoBgLargeScreen, bottomBarHeight}: InformationProps) {
  const theme = useTheme();
  const largeScreen = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <>
      <Swiper
        spaceBetween={50}
        slidesPerView={1}
        style={{
          width: "100%",
          height: "100%",
          position: "relative",
        }}
        pagination={{
          clickable: true,
          renderBullet: (i, className) => {
            return `<span class="${className}" style="background-color:${plantColor}; border:1px solid ${plantColor}; margin-bottom:${bottomBarHeight/2}px; border:2px solid black;"></span>`;
          }
        }}
        preloadImages
      >
        <SwiperSlide
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              backgroundImage: `url(${largeScreen ? infoBgLargeScreen : infoBgSmallScreen})`,
              backgroundSize: "contain",
              backgroundPosition: `bottom ${largeScreen ? "right" : "left"}`,
              backgroundRepeat: "no-repeat",
            }}
          />
          <div
            style={{
              color: plantColor,
              letterSpacing: largeScreen ? "0.26em" : "0.15em",
              fontSize: largeScreen ? "24px" : "0.8em",
              padding: "0 40px 20px 40px",
              textAlign: "left",
            }}
          >
            {
              largeScreen ? "INFORMATION" : header1
            }
          </div>
          <div
            style={{
              flex: "1 0 0",
              width: "100%",
              position: "relative",
            }}
          >
            <div
              style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                textAlign: "left",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  flexGrow: 1,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  padding: largeScreen ? "0 50px" : "0 80px",
                  lineHeight: "20px",
                  fontSize: "14px",
                }}
              >
                <div
                  style={{
                    borderLeft: largeScreen ? `1px solid ${plantColor}` : undefined,
                    paddingLeft: largeScreen ? "25px" : undefined,
                  }}
                >
                  {
                    text
                  }
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: largeScreen ? "none" : undefined,
              color: plantColor,
              letterSpacing: "0.15em",
              fontSize: "0.8em",
              padding: "0 40px 20px 40px",
              textAlign: "left",
              zIndex: 1,
            }}
          >
            {
              header1
            }
          </div>
          <div
            style={{
              flex: "1 0 0",
              width: "100%",
              position: "relative",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                backgroundImage: `url(${largeImage})`,
                backgroundSize: "contain",
                backgroundPosition: `bottom ${largeScreen ? "center" : "right"}`,
                backgroundRepeat: "no-repeat",
                transform: `scale(${largeScreen ? 1.1 : 1.15}) translateX(${largeScreen ? 0 : "15%" })`,
                transformOrigin: "bottom right",
              }}
            />
          </div>
          <div
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              backgroundImage: `url(${largeScreen ? infoBgLargeScreen : infoBgSmallScreen})`,
              backgroundSize: "contain",
              backgroundPosition: `bottom ${largeScreen ? "right" : "left"}`,
              backgroundRepeat: "no-repeat",
            }}
          />
        </SwiperSlide>
        <SwiperSlide
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              color: plantColor,
              letterSpacing: largeScreen ? "0.26em" : "0.15em",
              fontSize: largeScreen ? "24px" : "0.8em",
              padding: "0 40px 20px 40px",
              textAlign: "left",
            }}
          >
            {
              largeScreen ? "TERPENES" : header2
            }
          </div>
          <div
            style={{
              flex: "1 0 0",
              overflow: "auto",
              position: "relative",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              paddingRight: largeScreen ? "calc(5.5% + 10px + 25px)" : undefined,
              marginBottom: `${bottomBarHeight}px`,
            }}
          >
            <div
              style={{
                flex: "20 0 0",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  flex: "1 0 0",
                  backgroundImage: `url(${macroImage})`,
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                }}
              />
              <div
                style={{
                  textAlign: "left",
                  backgroundColor: plantColor,
                  color: "black",
                  padding: "10px 40px",
                  fontSize: "0.9em",
                }}
              >
                { description }
              </div>
            </div>
            <div
              style={{
                flex: "1 0 0",
              }}
            />
            <div
              style={{
                flex: "20 0 0",
                padding: "0 40px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-evenly",
              }}
            >
              {
                infoItems.map((e, i) => (
                  <div
                    key={i}
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          width: "50px",
                          height: "50px",
                          backgroundImage: `url(${e.bullet})`,
                          backgroundPosition: "center",
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "contain",
                        }}
                      />
                      <div
                        style={{
                          borderBottom: `3px solid ${plantColor}`,
                          fontWeight: "bold",
                        }}
                      >
                        {
                          e.title
                        }
                      </div>
                    </div>
                    <div
                      style={{
                        textAlign: "left",
                        marginLeft: "25px",
                        fontSize: "0.9em",
                      }}
                    >
                      <ul
                        style={{
                          listStyleType: "disc",
                          margin: 0,
                        }}
                      >
                        <li>
                          {
                            e.description
                          }
                        </li>
                      </ul>
                    </div>
                  </div>
                ))
              }
            </div>
          </div>
          {
            largeScreen ? (
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  bottom: 0,
                  left: 0,
                  backgroundImage: `url(${largeScreen ? infoBgLargeScreen : infoBgSmallScreen})`,
                  backgroundSize: "contain",
                  backgroundPosition: `bottom ${largeScreen ? "right" : "left"}`,
                  backgroundRepeat: "no-repeat",
                }}
              />
            ) : null
          }
        </SwiperSlide>
      </Swiper>
    </>
  );
}

type DetailProps = {
  video: string,
};

function Detail({video}: DetailProps) {
  return (
    <>
      <div
        style={{
          position: "absolute",
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          overflow: "hidden",
        }}
      >
        <video
          src={video}
          autoPlay
          loop
          style={{
            maxHeight: "100%",
            outline: "none",
          }}
          playsInline
        />
      </div>
    </>
  )
}

const sidebarData: {
  [key: string]: {
    [key: string]: (bottomBarHeight: number) => JSX.Element,
    details: (bottomBarHeight: number) => JSX.Element,
    information: (bottomBarHeight: number) => JSX.Element,
  }
} = {
  "cherry-ak": {
    details: () => (
      <Detail
        video={cherryAkDetailMovie}
      />
    ),
    information: bottomBarHeight => (
      <Information
        plantColor={plantColors["cherry-ak"]}
        header1="CHERRY AK | SATIVA DOMINANT"
        header2="CHERRY AK | TERPENES"
        text={(
          <>
            <p>
            Famous since the early 90s as a rare phenotype of the more widely available AK-47 strain. The round, "cherry-shaped" buds provide a sweet flavor and heady, uplifting energy boost that's great in the daytime.
            </p>
            <p>
              <b>Feelings:</b> Cerebral, focused euphoria with some body<br />
              <b>Flavors:</b> Sweet-cherry, minty, earthy<br />
              <b>Lineage:</b> Cherry phenotype of AK-47 (combination of Colombian, Mexican, Thai & Afghani landraces)<br />
            </p>
          </>
        )}
        infoItems={[
          {
            title: "Myrcene",
            description: "Found in mangoes & hops; earthy, fruity & pain-relieving",
            bullet: cherryAkBullet1,
          },
          {
            title: "Beta-Caryophyllene",
            description: "Found in black pepper and cloves; spicy & anti-inflammatory",
            bullet: cherryAkBullet2,
          },
          {
            title: "Pinene",
            description: "Found in rosemary and basil; piney & anti-anxiety",
            bullet: cherryAkBullet3,
          }
        ]}
        description={
          <>
            Inside the translucent trichomes are cannabinoids and terpenes that determine <b>Cherry AK's</b> smell and effects. The primary 3 terpenes are:
          </>
        }
        largeImage={cherryAkLarge}
        macroImage={terpenesCherryAk}
        infoBgSmallScreen={infoBgCherryAkSmallScreen}
        infoBgLargeScreen={infoBgCherryAkLargeScreen}
        bottomBarHeight={bottomBarHeight}
      />
    ),
  },
  "ice-cream-cake": {
    details: () => (
      <Detail
        video={iceCreamCakeDetailMovie}
      />
    ),
    information: bottomBarHeight => (
      <Information
        plantColor={plantColors["ice-cream-cake"]}
        header1="ICE CREAM CAKE | INDICA DOMINANT"
        header2="ICE CREAM CAKE | TERPENES"
        text={(
          <>
            <p>
            A contemporary combination of two modern classics, with beautiful purple hues and powerfully sedative effects. Those are produced by the presence of anthocyanins, the same compound found in blackberries and beets.
            </p>
            <p>
              <b>Feelings:</b> Soothing, sedative and sleepy<br />
              <b>Flavors:</b> Gasoline cookie dough, creamy vanilla skunk<br />
              <b>Lineage:</b> Wedding Cake x Gelato #33<br />
            </p>
          </>
        )}
        infoItems={[
          {
            title: "Limonene",
            description: "Found in lemon and citrus; orangey & uplifting",
            bullet: hellfireBullet1,
          },
          {
            title: "Beta-Caryophyllene",
            description: "Found in black pepper and cloves; spicy & anti-inflammatory",
            bullet: hellfireBullet2,
          },
          {
            title: "Linalool",
            description: "Found in lavender and citrus; floral & sedative",
            bullet: hellfireBullet3,
          }
        ]}
        description={
          <>
            Inside the translucent trichomes are cannabinoids and terpenes that determine <b>Ice Cream Cake's</b> smell and effects. The primary 3 terpenes are:
          </>
        }
        largeImage={iceCreamCakeLarge}
        macroImage={terpenesIceCreamCake}
        infoBgSmallScreen={infoBgIceCreamCakeSmallScreen}
        infoBgLargeScreen={infoBgIceCreamCakeLargeScreen}
        bottomBarHeight={bottomBarHeight}
      />
    ),
  },
  "hellfire": {
    details: () => (
      <Detail
        video={hellfireDetailMovie}
      />
    ),
    information: bottomBarHeight => (
      <Information
        plantColor={plantColors["hellfire"]}
        header1="HELLFIRE | INDICA DOMINANT"
        header2="HELLFIRE | TERPENES"
        text={(
          <>
            <p>
              Two beloved OG varieties come together in this Glass House staple strain that leans to the Indica side. Provides a huge mood lift and a trip into a creative, daydreamy headspace that isn't overly sleepy.
            </p>
            <p>
              <b>Feelings:</b> Uplifted, loose, open-minded relaxation<br />
              <b>Flavors:</b> Earthy, sweet, lemon<br />
              <b>Lineage:</b> Rascal's OG Kush x SFV OG Kush<br />
            </p>
          </>
        )}
        infoItems={[
          {
            title: "Limonene",
            description: "Found in lemon and citrus; orangey & uplifting",
            bullet: iceCreamCakeBullet1,
          },
          {
            title: "Beta-Caryophyllene",
            description: "Found in black pepper and cloves; spicy & anti-inflammatory",
            bullet: iceCreamCakeBullet2,
          },
          {
            title: "Humulene",
            description: "Found in hops and sage; woody & anti-microbial",
            bullet: iceCreamCakeBullet3,
          }
        ]}
        description={
          <>
            Inside the translucent trichomes are cannabinoids and terpenes that determine <b>Hellfire's</b> smell and effects. The primary 3 terpenes are:
          </>
        }
        largeImage={hellfireLarge}
        macroImage={terpenesHellfire}
        infoBgSmallScreen={infoBgHellfireSmallScreen}
        infoBgLargeScreen={infoBgHellfireLargeScreen}
        bottomBarHeight={bottomBarHeight}
      />
    ),
  },
};

const usePlantTransition = (plantName: string, loaded: boolean, leftPlant: string, centerPlant: string, rightPlant: string, viewerRef: React.RefObject<TurntableViewerRef>, selectedMarker: string | undefined, veryLargeScreen: boolean) => {
  useSpring({
    from: {
      posH: (leftPlant === plantName ? -2 : (centerPlant === plantName ? 0 : (rightPlant === plantName ? 2 : undefined))),
      posD: 2,
    },
    to: {
      posH: !loaded ? undefined : (leftPlant === plantName ? -1 : (centerPlant === plantName ? 0 : (rightPlant === plantName ? 1 : undefined))),
      posD: !loaded ? undefined : (centerPlant === plantName ? 0 : (!selectedMarker ? 1 : 2)),
    },
    config: {
      duration: veryLargeScreen ? undefined : 0,
    },
    onChange: val => {
      if(!viewerRef.current || val.posH === undefined || val.posD === undefined) return;
      const pos = new THREE.Vector3(
        mapRange(val.posH, -1, 1, -90, 90),
        mapRange(Math.abs(val.posD), 0, 1, 0, -5),
        mapRange(Math.abs(val.posD), 0, 1, 0, -20),
      );
      viewerRef.current.setPosition(pos);
      viewerRef.current.setAlpha(mapRange(val.posD, 0, 2, 1, 0));
    },
  });
}

type AllPlants3DProps = {
  plantType: string;
  selectedMarker: string | undefined;
  setSelectedMarkerRef: React.MutableRefObject<React.Dispatch<React.SetStateAction<string | undefined>>>;
  viewportOverlayRef: React.RefObject<HTMLDivElement>;
};

function AllPlants3D({plantType, selectedMarker, setSelectedMarkerRef, viewportOverlayRef}: AllPlants3DProps) {
  const leftPlant = useMemo(() => plants[wrapAround(plants.findIndex(n => n === plantType) - 1, 3)], [plantType]);
  const centerPlant = useMemo(() => plants[plants.findIndex(n => n === plantType)], [plantType]);
  const rightPlant = useMemo(() => plants[wrapAround(plants.findIndex(n => n === plantType) + 1, 3)], [plantType]);

  const viewerCherryAkRef = useRef<TurntableViewerRef>(null);
  const viewerIceCreamCakeRef = useRef<TurntableViewerRef>(null);
  const viewerHellfireRef = useRef<TurntableViewerRef>(null);

  const [cherryAkLoaded, setCherryAkLoaded] = useState(false);
  const [iceCreamCakeLoaded, setIceCreamCakeLoaded] = useState(false);
  const [hellfireLoaded, setHellfireLoaded] = useState(false);

  const theme = useTheme();
  const veryLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));
  usePlantTransition("cherry-ak", cherryAkLoaded, leftPlant, centerPlant, rightPlant, viewerCherryAkRef, selectedMarker, veryLargeScreen);
  usePlantTransition("ice-cream-cake", iceCreamCakeLoaded, leftPlant, centerPlant, rightPlant, viewerIceCreamCakeRef, selectedMarker, veryLargeScreen);
  usePlantTransition("hellfire", hellfireLoaded, leftPlant, centerPlant, rightPlant, viewerHellfireRef, selectedMarker, veryLargeScreen);

  const { camera, scene } = useThree();

  useFrame(({ gl }) => {
    gl.autoClear = false;
    camera.position.set(0, 5, 60);
    camera.rotation.set(0, 0, 0);
    if(scene) {
      gl.render(scene, camera);
    }
    gl.clearDepth();

    if(arrowSceneRef.current) {
      gl.render(arrowSceneRef.current, camera);
    }
  }, 1);

  const arrowSceneRef = useRef<THREE.Scene>();
  const arrows = useLoader(SVGLoader, circleImg);
  const arrowMeshes = useMemo(() => arrows.paths.map(p => p.toShapes(true)).flat().map(s => new THREE.ShapeGeometry(s)), [arrows]);
  const arrowsRef = useRef<THREE.Object3D>();
  useFrame((state, delta) => {
    if(!arrowsRef.current) return;
    arrowsRef.current.rotation.y -= delta * 0.2;
    invalidate();
  });

  return (
    <>
      <Suspense
        fallback={
          <ViewportLoadingSpinner
            viewportOverlayRef={viewportOverlayRef}
          />
        }
      >
        {
          veryLargeScreen || plantType === "cherry-ak" ? (
            <TurntableViewerCherryAk
              plantRef={viewerCherryAkRef}
              active={plantType === "cherry-ak"}
              selectedMarker={selectedMarker}
              setSelectedMarker={setSelectedMarkerRef.current}
              markerData={markerDataCherryAk}
              plantColor={plantColors["cherry-ak"]}
              onLoad={() => setCherryAkLoaded(true)}
            />
          ) : null
        }
      </Suspense>
      <Suspense
        fallback={
          <ViewportLoadingSpinner
            viewportOverlayRef={viewportOverlayRef}
          />
        }
      >
        {
          veryLargeScreen || plantType === "ice-cream-cake" ? (
            <TurntableViewerIceCreamCake
              plantRef={viewerIceCreamCakeRef}
              active={plantType === "ice-cream-cake"}
              selectedMarker={selectedMarker}
              setSelectedMarker={setSelectedMarkerRef.current}
              markerData={markerDataIceCreamCake}
              plantColor={plantColors["ice-cream-cake"]}
              onLoad={() => setIceCreamCakeLoaded(true)}
            />
          ) : null
        }
      </Suspense>
      <Suspense
        fallback={
          <ViewportLoadingSpinner
            viewportOverlayRef={viewportOverlayRef}
          />
        }
      >
        {
          veryLargeScreen || plantType === "hellfire" ? (
            <TurntableViewerHellfire
              plantRef={viewerHellfireRef}
              active={plantType === "hellfire"}
              selectedMarker={selectedMarker}
              setSelectedMarker={setSelectedMarkerRef.current}
              markerData={markerDataHellfire}
              plantColor={plantColors["hellfire"]}
              onLoad={() => setHellfireLoaded(true)}
            />
          ) : null
        }
      </Suspense>
      <scene
        ref={arrowSceneRef}
      >
        <group
          ref={arrowsRef}
          rotation={[-0.3, 0, 0]}
          position={[0, 10, 0]}
        >
          {
            arrowMeshes.map((m, i) => (
              <mesh
                key={i}
                geometry={m}
                position={[0, -27.5, 0]}
                scale={[.02, .02, 6.5]}
                rotation={[-Math.PI/2, 0, 0]}
              >
                <meshBasicMaterial
                  color={new THREE.Color(plantColors[plantType]).convertSRGBToLinear()}
                />
              </mesh>
            ))
          }
        </group>
      </scene>
    </>
  );
}

type AllPlantsProps = {
  plantType: string;
  viewportOverlayRef: React.RefObject<HTMLDivElement>;
  history: H.History;
  selectedMarker: string | undefined;
  setSelectedMarker: React.Dispatch<React.SetStateAction<string | undefined>>;
  sidebarWidthPct: number;
  handleSoundPlaying: (newState: boolean, plantType?: string | undefined) => void;
};

function AllPlants({plantType, viewportOverlayRef, history, selectedMarker, setSelectedMarker, sidebarWidthPct, handleSoundPlaying}: AllPlantsProps) {
  const setSelectedMarkerRef = useRef<React.Dispatch<React.SetStateAction<string | undefined>>>(setSelectedMarker);
  setSelectedMarkerRef.current = setSelectedMarker;

  const theme = useTheme();
  const largeScreen = useMediaQuery(theme.breakpoints.up('md'));

  useEffect(() => {
    setSelectedMarkerRef.current(undefined);
  }, [plantType]);
  
  return (
      <div
        style={{
          position: "absolute",
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div
          style={{
            height: "100%",
            position: "relative",
            flex: `${largeScreen ? (100-sidebarWidthPct) : 1} 0 0`,
            transition: "all 0.5s ease-in-out",
          }}
        >
          <Canvas
            invalidateFrameloop
            style={{
              position: "absolute",
            }}
            resize={{debounce: -1}}
          >
            <Suspense
              fallback={
                <ViewportLoadingSpinner
                  viewportOverlayRef={viewportOverlayRef}
                />
              }
            >
              <AllPlants3D
                plantType={plantType}
                selectedMarker={selectedMarker}
                setSelectedMarkerRef={setSelectedMarkerRef}
                viewportOverlayRef={viewportOverlayRef}
              />
            </Suspense>
          </Canvas>
          <div
            style={{
              position: "absolute",
              top: "48px",
              marginTop: "10px",
              right: 0,
              bottom: 0,
              left: 0,
              pointerEvents: "none",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Button
              variant="outlined"
              style={{
                borderColor: plantColors[plantType],
                color: plantColors[plantType],
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                letterSpacing: "0.16em",
                borderRadius: 0,
              }}
              onClick={() => {
                handleSoundPlaying(true, plantType);
                history.push(`/experience/${allPlantTypes.find(p => p.type === plantType)?.route}`);
              }}
            >
              <div
                style={{
                  marginTop: "2px",
                  pointerEvents: "all",
                }}
              >
                EXPERIENCE
              </div>
            </Button>
          </div>
        </div>
        <div
          style={{
            height: "100%",
            flexGrow: largeScreen && selectedMarker && selectedMarker !== "markerexperience" ? sidebarWidthPct : 0,
            transition: "all 0.5s ease-in-out",
          }}
        />
      </div>
  );
}

export const allPlantTypes = [
  {
    route: "cherry-ak",
    type: "cherry-ak",
  }, {
    route: "ice-cream-cake",
    type: "ice-cream-cake",
  }, {
    route: "hellfire",
    type: "hellfire",
  },
];

type PlantViewerProps = {
  viewportOverlayRef: React.RefObject<HTMLDivElement>;
  history: H.History;
  routeMatchPlant: match<{
    plantType: string;
  }> | null;
  active: boolean;
  handleSoundPlaying: (newState: boolean, plantType?: string | undefined) => void;
};

export default function PlantViewer({viewportOverlayRef, history, routeMatchPlant, active, handleSoundPlaying}: PlantViewerProps) {
  const stepButtonHandler = (direction: number) => () => {
    if(!routeMatchPlant) return;
    const nextPlant = plants[wrapAround(plants.findIndex(n => n === routeMatchPlant.params.plantType) + direction, 3)];
    history.push(`/plant/${nextPlant}`);
  };
  
  const [selectedMarker, setSelectedMarker] = useState<string>();
  const prevSelectedMarker = useRef<string>();
  useEffect(() => {
    prevSelectedMarker.current = selectedMarker;
  }, [selectedMarker]);

  const currPlantType = useMemo(() => routeMatchPlant?.params.plantType, [routeMatchPlant]);
  const prevPlantType = useRef<string | undefined>(currPlantType);
  useEffect(() => {
    prevPlantType.current = currPlantType;
  }, [currPlantType]);

  const {ref: bottomBarRef, height: bottomBarHeight = 1} = useResizeObserver();

  const sidebarContent = useMemo(() => {
    if(!currPlantType) return null;
    if(!selectedMarker) {
      if(!prevSelectedMarker.current) return null;
      return sidebarData[allPlantTypes.find(p => p.route === currPlantType)?.type ?? ""][prevSelectedMarker.current](bottomBarHeight);
    }
    return sidebarData[allPlantTypes.find(p => p.route === currPlantType)?.type ?? ""][selectedMarker](bottomBarHeight);
  }, [currPlantType, selectedMarker, bottomBarHeight]);

  const theme = useTheme();
  const largeScreen = useMediaQuery(theme.breakpoints.up('md'));
  const sidebarWidthPct = largeScreen ? 50 : 100;

  usePageView(active && routeMatchPlant ? true : false, `/plant/${routeMatchPlant?.params.plantType}`, `Plant ${plantTitles[routeMatchPlant?.params.plantType ?? ""]?.title}`);

  return (
    <RouteWrapper
      active={active}
      style={{
        backgroundColor,
        backgroundSize: "100% 100%",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          backgroundColor,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            flex: "1 0 0",
            position: "relative",
          }}
        >
          {
            routeMatchPlant ? (
              <AllPlants
                plantType={routeMatchPlant.params.plantType}
                viewportOverlayRef={viewportOverlayRef}
                history={history}
                selectedMarker={selectedMarker}
                setSelectedMarker={setSelectedMarker}
                sidebarWidthPct={sidebarWidthPct}
                handleSoundPlaying={handleSoundPlaying}
              />
            ) : null
          }
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            transition: "all 0.5s",
          }}
        >
          <div
            ref={bottomBarRef}
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              padding: "16px",
              flex: `${!largeScreen ? 1 : (100-sidebarWidthPct)} 0 0`,
              transition: "all 0.5s",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <IconButton
                style={{
                  color: "white",
                }}
                onClick={stepButtonHandler(-1)}
              >
                <ChevronLeftIcon fontSize="large" />
              </IconButton>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                style={{
                  fontSize: largeScreen ? "36px" : "20px",
                  letterSpacing: largeScreen ? "0.16em" : "0.16em",
                  color: "white",
                  fontFamily: "NoirStd-Medium",
                  textAlign: "center",
                }}
              >
                {
                  routeMatchPlant ? plantTitles[routeMatchPlant.params.plantType]?.title : ""
                }
              </Typography>
              <Typography
                style={{
                  fontSize: largeScreen ? "24px" : "12px",
                  letterSpacing: "0.26em",
                  color: routeMatchPlant ? plantColors[routeMatchPlant.params.plantType] : undefined,
                }}
              >
                {
                  routeMatchPlant ? plantTitles[routeMatchPlant.params.plantType]?.subtitle : ""
                }
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <IconButton
                style={{
                  color: "white",
                }}
                onClick={stepButtonHandler(1)}
              >
                <ChevronRightIcon fontSize="large" />
              </IconButton>
            </div>
          </div>
          <div
            style={{
              flex: `${!largeScreen ? 0 : (selectedMarker && selectedMarker !== "markerexperience" ? sidebarWidthPct : 0)} 0 0`,
              transition: "all 0.5s ease-in-out",
            }}
          />
        </div>
        <div
          style={{
            position: "absolute",
            top: 0,
            right: selectedMarker && selectedMarker !== "markerexperience" ? 0 : `-${sidebarWidthPct}%`,
            bottom: 0,
            width: `${sidebarWidthPct}%`,
            transition: "right 0.5s ease-in-out",
            backgroundColor: "black",
            color: "white",
            overflow: "auto",
            zIndex: 2147483646,
            display: "flex",
          }}
        >
          <div
            style={{
              padding: selectedMarker === "details" ? undefined : "50px 0 0 0",
              width: "100%",
              position: "relative",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                flex: "1 0 0",
              }}
            >
              { sidebarContent }
            </div>
          </div>
          <div
            style={{
              position: "absolute",
              top: 0,
              right: 0,
            }}
          >
            <IconButton
              onClick={() => setSelectedMarker(undefined)}
              style={{
                color: "white",
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </div>
      </div>
    </RouteWrapper>
  );
}
