import { ThemeProvider, Typography, useMediaQuery } from "@material-ui/core";
import { Html } from "@react-three/drei";
import React, { useState, useRef, useMemo } from "react";
import { useThree, useFrame, ObjectMap } from "react-three-fiber";
import * as THREE from "three";
import { GLTF } from "three/examples/jsm/loaders/GLTFLoader";
import { theme } from ".";
import Logo from "./Logo";

const markerSize = 45;

export type MarkerData = {
  title: string;
  icon: "logoCherryAk" | "logoIceCreamCake" | "logoHellfire" | "information" | "details" | "experience";
  route?: string;
}

type MarkerProps = {
  isForeground: boolean;
  selectedMarker: string | undefined;
  setSelectedMarker: React.Dispatch<React.SetStateAction<string | undefined>>;
  marker: THREE.Object3D;
  markerData: {
    [key: string]: MarkerData;
  },
  onClick?: () => void,
  maxMarkerSize?: number,
  scale: number,
  color?: string;
};

function Marker({isForeground, selectedMarker, setSelectedMarker, marker, markerData, onClick, maxMarkerSize = 200, scale, color}: MarkerProps) {
  const [isHighlighted, setIsHighlighted] = useState(false);
  const unHighlightTimeoutRef = useRef<NodeJS.Timeout>();
  const isSelected = selectedMarker === marker.name;

  const largeScreen = useMediaQuery(theme.breakpoints.up('md'));

  const colorR = parseInt((color ?? theme.palette.primary.main).slice(1, 3), 16);
  const colorG = parseInt((color ?? theme.palette.primary.main).slice(3, 5), 16);
  const colorB = parseInt((color ?? theme.palette.primary.main).slice(5, 7), 16);

  return (
    <group
      position={marker.position}
    >
      <Html
        style={{
          transform: "translate(0, -10px)",
        }}
      >
        <ThemeProvider theme={theme}>
          <div
            key={1}
            style={{
              height: `${markerSize}px`,
              width: largeScreen && (isHighlighted || isSelected) ? `${maxMarkerSize}px` : `${markerSize}px`,
              backgroundColor: isForeground ? `rgba(${colorR}, ${colorG}, ${colorB}, 0.5)` : "transparent",
              opacity: isForeground ? 1 : 0.15,
              borderRadius: `${markerSize/2}px`,
              pointerEvents: isForeground ? undefined : "none",
              overflow: "hidden",
              transition: "all 0.2s ease-in",
              cursor: "pointer",
              position: "relative",
              transform: `scale(${1})`,
            }}
            onClick={e => {
              if(isForeground) {
                e.stopPropagation();
                setSelectedMarker(marker.name);
                if(onClick) onClick();
              }
            }}
            onPointerOver={() => {
              clearTimeout(unHighlightTimeoutRef.current as unknown as number);
              unHighlightTimeoutRef.current = undefined;
              setIsHighlighted(true);
            }}
            onPointerOut={() => {
              unHighlightTimeoutRef.current = setTimeout(() => {
                setIsHighlighted(false);
              }, selectedMarker ? 0 : 1000);
            }}
          >
            <div
              style={{
                backgroundImage: `radial-gradient(50px at left, rgba(0, 0, 0, 0.4), 75%, rgba(0, 0, 0, 0))`,
                position: "absolute",
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                width: `${maxMarkerSize}px`,
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                paddingLeft: `${markerSize+7}px`,
              }}
            >
              <Typography>
                {markerData[marker.name].title}
              </Typography>
            </div>
            <div
              style={{
                borderRadius: `${markerSize/2}px`,
                width: `${markerSize}px`,
                height: `${markerSize}px`,
                backgroundColor: color ?? theme.palette.primary.main,
                position: "absolute",
                left: 0,
                top: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Logo
                type={markerData[marker.name].icon}
              />
            </div>
          </div>
        </ThemeProvider>
      </Html>
    </group>
  );
}

type MarkersProps = {
  markerModel: GLTF & ObjectMap;
  selectedMarker: string | undefined;
  setSelectedMarker: React.Dispatch<React.SetStateAction<string | undefined>>;
  markerData: {
    [key: string]: MarkerData;
  };
  maxMarkerSize?: number,
  scale?: number,
  color?: string;
};

export default function Markers({markerModel, markerData, selectedMarker, setSelectedMarker, maxMarkerSize = 200, scale = 1, color}: MarkersProps) {
  const markers = useMemo(() => (markerModel.scene.getObjectByName("markers") as THREE.Object3D).children, [markerModel]);
  const [markerVisible, setMarkerVisible] = useState<boolean[]>(new Array(markers.length).fill(false));

  const { camera } = useThree();
  const sceneRef = useRef<THREE.Object3D>();
  const markerParentRef = useRef<THREE.Object3D>();
  useFrame(({ gl }) => {
    if(!markerParentRef.current) return;
    const refRot = markerModel.scene.children.find(o => o.name === "markers")?.rotation;
    if(refRot) markerParentRef.current.rotation.copy(refRot);
    const cameraWorldPos = camera.getWorldPosition(new THREE.Vector3());
    cameraWorldPos.y = 0;
    const markerDistances = markerParentRef.current.children.map(m => {
      const markerWorldPos = m.getWorldPosition(new THREE.Vector3());
      const cameraWorldPos = camera.getWorldPosition(new THREE.Vector3());
      return (new THREE.Plane().setFromNormalAndCoplanarPoint(new THREE.Vector3(cameraWorldPos.x, 0, cameraWorldPos.z), new THREE.Vector3(markerWorldPos.x, 0, markerWorldPos.z)).distanceToPoint(new THREE.Vector3(0, 0, 0)));
    });
    for (const [i, dist] of markerDistances.entries()) {
      const newMarkerVisible = dist < 0.01 ? true : false;
      if(markerVisible[i] !== newMarkerVisible) {
        setMarkerVisible(x => {
          const newX = [...x];
          newX[i] = newMarkerVisible;
          return newX;
        });
      }
    }

    if(!sceneRef.current) return;
    gl.autoClear = false;
    gl.render(sceneRef.current, camera);
  }, 1);

  return (
    <scene
      ref={sceneRef}
    >
      <group
        ref={markerParentRef}
      >
        {
          markers.map((m, i) => {
            return (
              <Marker
                key={i}
                isForeground={markerVisible[i]}
                selectedMarker={selectedMarker}
                setSelectedMarker={setSelectedMarker}
                marker={m}
                markerData={markerData}
                maxMarkerSize={maxMarkerSize}
                scale={scale}
                color={color}
              />
            );
          })
        }
      </group>
    </scene>
  );
}
