import { useTheme, useMediaQuery, Button, Typography, CircularProgress } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { ControlsRef } from './DefaultControls';
import Logo from './Logo';
import logo360 from './media/logo360.svg';

const useOverlayStuff:
  (loaded: boolean, useDeviceControls: boolean, controlsRef: React.RefObject<ControlsRef>, autoClose: boolean) => [boolean, boolean, () => void] =
  (loaded: boolean, useDeviceControls: boolean, controlsRef: React.RefObject<ControlsRef>, autoClose: boolean) => {
  const needInteraction = window.DeviceOrientationEvent && 'ontouchstart' in window && typeof window.DeviceOrientationEvent.requestPermission === 'function';

  const [overlayHidden, setOverlayHidden] = useState(true);
  useEffect(() => {
    if(!loaded || !autoClose) return;
    if(useDeviceControls) {
      setOverlayHidden(true);
    }
    if(!needInteraction) {
      setTimeout(() => {
        setOverlayHidden(true);
      }, 5000);
    }
  }, [useDeviceControls, loaded, needInteraction, autoClose]);

  useEffect(() => {
    setOverlayHidden(false);
  }, []);

  const enter = () => {
    if(controlsRef.current) {
      controlsRef.current.createDeviceOrientationControls();
      if(typeof window.DeviceOrientationEvent.requestPermission === 'function') {
        window.DeviceOrientationEvent.requestPermission().finally(() => {
          setOverlayHidden(true);
        });
      } else {
        setOverlayHidden(true);
      }
    }
  }

  return [needInteraction, overlayHidden, enter];
}

var gyroPresent = typeof window.DeviceOrientationEvent.requestPermission === 'function';
window.addEventListener("devicemotion", event => {
  if(!event?.rotationRate) return;
  if(event.rotationRate.alpha || event.rotationRate.beta || event.rotationRate.gamma)
    gyroPresent = true;
});

type Overlay360ExperienceProps = {
  controlsRef: React.RefObject<ControlsRef>;
  useDeviceControls: boolean;
  plantTitle: string;
  plantSubtitle: string;
  loaded: boolean;
  plantColor: string;
  titleImage: string;
  titleImageHPosition: string;
};

export function Overlay360Experience({controlsRef, useDeviceControls, plantTitle, plantSubtitle, loaded, plantColor, titleImage, titleImageHPosition}: Overlay360ExperienceProps) {
  const theme = useTheme();
  const largeScreen = useMediaQuery(theme.breakpoints.up('md'));

  const [, overlayHidden, enter] = useOverlayStuff(loaded, useDeviceControls, controlsRef, false);

  const explainItems = [
    {
      title: "Interactive Navigation",
      logo: (('ontouchstart' in window) || (navigator.maxTouchPoints > 0)) ? "logoTouch" : "logoKeyboard",
    },
    {
      title: "Interactive Viewer",
      logo: gyroPresent ? "logo360" : ((('ontouchstart' in window) || (navigator.maxTouchPoints > 0)) ? "logoTouch" : "logoMouse"),
    },
    {
      title: "Discount Code",
      logo: "logoDiscount",
    },
  ];

  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        pointerEvents: "none",
        backgroundColor: "black",
        display: "flex",
        flexDirection: largeScreen ? "row" : "column",
        alignItems: "center",
        opacity: overlayHidden ? 0 : 1,
        transition: "opacity 0.5s ease-in-out",
        zIndex: 2147483647,
      }}
    >
      <div
        style={{
          flexGrow: 1,
          flexBasis: largeScreen ? 0 : undefined,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
          alignItems: "center",
          backgroundColor: largeScreen ? "black" : plantColor,
          padding: "30px",
          width: largeScreen ? undefined : "100%",
          height: largeScreen ? "100%" : undefined,
        }}
      >
        <div>
          <Typography
            style={{
              fontFamily: "NoirStd-Medium",
              fontSize: largeScreen ? "36px" : "20px",
              lineHeight: largeScreen ? "42px" : "23px",
              textAlign: "center",
              letterSpacing: "0.16em",
              marginBottom: "5px",
              color: largeScreen ? "white" : undefined,
            }}
          >
            {plantTitle}
          </Typography>
          <Typography
            style={{
              fontFamily: "NoirStd-Regular",
              fontSize: largeScreen ? "24px" : "12px",
              lineHeight: largeScreen ? "28px" : "14px",
              textAlign: "center",
              letterSpacing: "0.26em",
              color: largeScreen ? plantColor : undefined,
            }}
          >
            {plantSubtitle}
          </Typography>
        </div>
        <div
          style={{
            fontFamily: "NoirStd-Regular",
            color: largeScreen ? "white" : undefined,
            fontSize: largeScreen ? "22px" : "14px",
            lineHeight: largeScreen ? "34px" : "20px",
            margin: largeScreen ? "0 100px" : undefined,
            padding: largeScreen ? undefined : "0 30px",
          }}
        >
          {
            "We've created a set of expressive virtual installations and digitally placed them at a location within Santa Barbara, CA. Enjoy exploring an audio-visual experience representing one of the three cannabis strains and don't forget to find your 420 discount code!"
          }
        </div>
        {
          largeScreen ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-evenly",
                width: "calc(100% - 50px)",
              }}
            >
              {
                explainItems.map(({title, logo}, i) => (
                  <div
                    key={i}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        width: "75px",
                        height: "75px",
                        borderRadius: "50%",
                        backgroundColor: plantColor,
                      }}
                    >
                      <Logo type={logo} />
                    </div>
                    <div
                      style={{
                        fontFamily: "NoirStd-Medium",
                        fontSize: "16px",
                        lineHeight: "21px",
                        textAlign: "center",
                        letterSpacing: "0.16em",
                        color: "white",
                        width: "0",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        marginTop: "30px",
                      }}
                    >
                      <div>
                        {title}
                      </div>
                    </div>
                  </div>
                ))
              }
            </div>
        ) : null
      }
      </div>
      <div
        style={{
          flexGrow: 1.25,
          flexBasis: largeScreen ? 0 : undefined,
          display: "flex",
          flexDirection: "column",
          justifyContent: largeScreen ? "flex-end" : "space-evenly",
          alignItems: "center",
          color: "white",
          width: largeScreen ? undefined : "calc(100% - 60px)",
          height: largeScreen ? "100%" : undefined,
          background: largeScreen ? `url(${titleImage})` : undefined,
          backgroundSize: largeScreen ? "cover" : undefined,
          backgroundPosition: largeScreen ?`${titleImageHPosition} center` : undefined,
          backgroundRepeat: largeScreen ? "no-repeat" : undefined,
          paddingBottom: largeScreen ? "5%" : undefined,
        }}
      >
        {
          !largeScreen ? (
            explainItems.map(({title, logo}, i) => (
              <div
                key={i}
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    fontFamily: "NoirStd-Medium",
                    fontSize: "16px",
                    lineHeight: "21px",
                    textAlign: "center",
                    letterSpacing: "0.16em",
                  }}
                >
                  {title}
                </div>
                <div>
                  <Logo type={logo === "logoDiscount" ? "logoDiscountWhite" : (logo === "logo360" ? "logo360White" : logo)} />
                </div>
              </div>
            ))
          ) : null
        }
        {
          !loaded ? (
            <div
              style={{
                height: "33px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <Button
              style={{
                width: largeScreen ? undefined : "100%",
                padding: largeScreen ? "6px 50px" : undefined,
                fontWeight: "bold",
                fontFamily: "NoirStd-Medium",
                fontSize: "12px",
                letterSpacing: "0.215em",
                borderRadius: 0,
                pointerEvents: "all",
                backgroundColor: plantColor,
              }}
              variant="contained"
              color="primary"
              onClick={() => {
                enter();
              }}
            >
              START
            </Button>
          )
        }
      </div>
    </div>
  );
}

type Overlay360GreenhouseProps = {
  controlsRef: React.RefObject<ControlsRef>;
  useDeviceControls: boolean;
  experienceName: string;
  loaded: boolean;
};

export function Overlay360Greenhouse({controlsRef, useDeviceControls, experienceName, loaded}: Overlay360GreenhouseProps) {
  const theme = useTheme();
  const largeScreen = useMediaQuery(theme.breakpoints.up('sm'));

  const [needInteraction, overlayHidden, enter] = useOverlayStuff(loaded, useDeviceControls, controlsRef, true);

  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        pointerEvents: "none",
        backgroundColor: "rgba(249, 237, 50, 0.5)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        opacity: overlayHidden ? 0 : 1,
        transition: "opacity 0.5s ease-in-out",
        zIndex: 2147483647,
      }}
    >
      <div
        style={{
          width: largeScreen ? "125px" : "30%",
          paddingTop: largeScreen ? "125px" : "30%",
          borderRadius: "50%",
          border: "9px solid black",
          position: "relative",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            bottom: "10px",
            left: "10px",
            backgroundImage: `url(${logo360})`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
          }}
        />
      </div>
      {
        loaded ? (
          <div
            style={{
              position: "absolute",
              bottom: "10%",
              marginLeft: "auto",
              marginRight: "auto",
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              onClick={() => {
                enter();
              }}
              style={{
                marginTop: "20px",
                fontFamily: "NoirStd-Medium",
                fontSize: "12px",
                letterSpacing: "0.215em",
                textAlign: "center",
                backgroundColor: "#F6E92B",
                color: "black",
                borderRadius: 0,
                padding: "6px 25px",
                pointerEvents: "all",
                display: needInteraction ? undefined : "none",
              }}
              variant="contained"
              color="primary"
            >
              {experienceName}
            </Button>
          </div>
        ) : null
      }
      <Typography
        style={{
          marginTop: "20px",
          fontFamily: "NoirStd-Medium",
          fontSize: "18px",
          letterSpacing: "0.16em",
          textAlign: "center",
          color: "black",
          display: needInteraction ? "none" : undefined,
        }}
      >
        {experienceName}
      </Typography>
      {
        !loaded ? (
          <div
            style={{
              height: "33px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <div
            style={{
              height: "33px",
            }}
          />
        )
      }
    </div>
  );
}
