export default {
    numHImages: 4,
    numVImages: 4,
    numTotalImages: 36,
    atlases: [
      
          process.env.PUBLIC_URL + "/5ac0945909076272a7ffc1ae21a2a585.jpeg",
        
          process.env.PUBLIC_URL + "/731fa38f236c8cab7c31a70349dc75d7.jpeg",
        
          process.env.PUBLIC_URL + "/7baea052097a5e3c8afb04133fe151ed.jpeg",
        
    ],
    aspect: 1,
  };