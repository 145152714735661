export default {
    numHImages: 4,
    numVImages: 4,
    numTotalImages: 36,
    atlases: [
      
          process.env.PUBLIC_URL + "/7bd5c3ef9c8fe540f2069ffcaef074dc.jpeg",
        
          process.env.PUBLIC_URL + "/5ed28884d41b6e9e1820a5103a6437bf.jpeg",
        
          process.env.PUBLIC_URL + "/4d4ab417a945949595815b5bd4d9d76d.jpeg",
        
    ],
    aspect: 1,
  };