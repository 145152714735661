export default {
    numHImages: 4,
    numVImages: 4,
    numTotalImages: 36,
    atlases: [
      
          process.env.PUBLIC_URL + "/0448d82ce76fbd29b4e9e4b484de1eb6.jpeg",
        
          process.env.PUBLIC_URL + "/18228051f1be847bcc277ce5ac6eeda7.jpeg",
        
          process.env.PUBLIC_URL + "/2f9c27ef52fab1d256941b762043f9ed.jpeg",
        
    ],
    aspect: 1,
  };