import { Sphere } from '@react-three/drei';
import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';
import mergeRefs from 'react-merge-refs';
import { useThree } from 'react-three-fiber';
import { createAsset } from 'use-asset';

export type PhotosphereType = {
  dimensions: {
    width: number,
    height: number,
    subdivisionsH: number,
    subdivisionsV: number,
  },
  image: string[][],
};

const photosphereAsset = createAsset<THREE.Texture[][], [photosphere: PhotosphereType, gl: THREE.WebGLRenderer]>(
  (photosphere, gl) => new Promise(resolve => {
    const basisLoader = new THREE.TextureLoader();
    let textures: THREE.Texture[][] = [];
    const promises = [];
    for(let i = 0; i < photosphere.dimensions.subdivisionsH; i++) {
      for(let j = 0; j < photosphere.dimensions.subdivisionsV; j++) {
        if(textures[i] === undefined) textures[i] = [];
        promises.push(new Promise<void>(res => {
          basisLoader.load(photosphere.image[i][j], texture => {
            texture.encoding = THREE.sRGBEncoding;
            textures[i][j] = texture;
            res();
          });
        }));
      }
    }

    Promise.all(promises).then(() => {
      resolve(textures);
    });
  })
);

type PhotosphereProps = {
  photosphere: PhotosphereType,
  radius: number,
  transparent?: boolean,
  position?: THREE.Vector3,
  visible?: boolean,
  quaternion?: THREE.Quaternion,
  setLoaded: React.Dispatch<React.SetStateAction<boolean>>;
};
const Photosphere = React.forwardRef(({photosphere, radius, transparent, position, visible = true, quaternion, setLoaded} : PhotosphereProps, ref) => {
  const containerRef = useRef<THREE.Group>();

  const { gl } = useThree();
  const textures = photosphereAsset.read(photosphere, gl);
  const dimensions = photosphere.dimensions;

  useEffect(() => {
    if(!textures) return;
    for(let i = 0; i < dimensions.subdivisionsH; i++) {
      for(let j = 0; j < dimensions.subdivisionsV; j++) {
        if(containerRef.current) {
          if(!containerRef.current.children[i].children[j].hasOwnProperty("material")) return;
          const o = containerRef.current.children[i].children[j] as THREE.Mesh;
          (o.material as THREE.MeshStandardMaterial).needsUpdate = true;
        }
      }
    }
  }, [dimensions, textures]);

  useEffect(() => {
    setLoaded(true);
  }, [setLoaded]);


  return (
    <group
      ref={mergeRefs([ref, containerRef])}
      position={position}
      quaternion={quaternion}
      visible={visible}
    >
      {
        Array(dimensions.subdivisionsH).fill(0).map((a, i) => (
          <group
            key={i}
          >
            {
              Array(dimensions.subdivisionsV).fill(0).map((b, j) => (
                <Sphere
                  key={j}
                  args={[radius, 128, 128, i*2*Math.PI/dimensions.subdivisionsH, 2*Math.PI/dimensions.subdivisionsH, j*Math.PI/dimensions.subdivisionsV, Math.PI/dimensions.subdivisionsV]}
                  scale={[-1, 1, 1]}
                >
                  <meshBasicMaterial
                    side={THREE.BackSide}
                    transparent={transparent}
                    map={textures ? textures[i][j] : undefined}
                    toneMapped={false}
                  />
                </Sphere>
              ))
            }
          </group>
        ))
      }
    </group>
  );
});

export default Photosphere;
