import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';
import 'firebase/analytics';
import firebase from 'firebase/app';

var firebaseConfig = {
  apiKey: "AIzaSyBOgVM5cjD_q1bsh7WEayewq6-hgfgFJmc",
  authDomain: "glasshouse-farms-experience.firebaseapp.com",
  projectId: "glasshouse-farms-experience",
  storageBucket: "glasshouse-farms-experience.appspot.com",
  messagingSenderId: "745152408883",
  appId: "1:745152408883:web:41cdfeaa651df230682246",
  measurementId: "G-0H3SWT8XTV"
};
firebase.initializeApp(firebaseConfig);
// https://stackoverflow.com/questions/64155540/how-to-disable-automatic-page-view-logging-in-firebase-analytics
// @ts-ignore
window.dataLayer = window.dataLayer || [];
// @ts-ignore
function gtag() { dataLayer.push(arguments); }
// @ts-ignore
gtag('set', { 'send_page_view': false });
firebase.analytics();

export const theme = responsiveFontSizes(createMuiTheme({
  palette: {
    primary: {
      main: "#F9EC31",
    },
    secondary: {
      main: grey[500],
    },
    text: {
      primary: grey[100],
    },
  },
  typography: {
    button: {
      textTransform: "none",
    },
    fontFamily: "NoirStd-Regular",
  },
}));

const checkBrowser = new Promise<void>(resolve => {
  if(!(window as any).ResizeObserver) {
    import('resize-observer-polyfill').then(r => {
      (window as any).ResizeObserver = r.default;
      resolve();
    });
  } else {
    resolve();
  }
});

checkBrowser.then(() => {
  ReactDOM.render(
    <React.StrictMode>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root')
  );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
